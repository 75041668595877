import React from 'react'
import MainRoutes from '@routes/Main.route'
import Toast from '@shared/components/toast/Toast'

import './App.css'

const App: React.FC = () => {
    return (
        <div style={{ height: '100%' }}>
            <MainRoutes />
            <Toast position={'top-right'} autoDelete={true} autoDeleteTime={5000} />
        </div>
    )
}

export default App
