import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { StikoType , StikoParams } from './StikoTable.types'
import { stikoResponse } from '@app-types/StikoTableResponse.types'


const initstage: StikoType = {
    stikoLoading: false,
    stiko: null,
    stikoError: null,
}

const StikoSlice = createSlice({
    name: 'StikoSlice',
    initialState: initstage,
    reducers: {
        getStiko(state, action: PayloadAction<StikoParams>) {
            state.stikoLoading = true
            state.stikoError = null
        },
        getStikoSuccess(state, action: PayloadAction<stikoResponse>) {
            state.stiko = action.payload
            state.stikoLoading = false
            state.stikoError = null
        },
        getStikoFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getStiko, getStikoSuccess, getStikoFailure } = StikoSlice.actions
export default StikoSlice.reducer
