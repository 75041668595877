import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface InitState {
    ProjectLoading: null | boolean;
    ProjectSucess: null | boolean;
    ProjectError: any
}

const initstage: InitState = {
   ProjectLoading: null,
   ProjectSucess: null,
   ProjectError:null
  
}

const addProjectSlice = createSlice({
    name: 'addProjectSlice',
    initialState: initstage,
    reducers: {
        resetProjectSuccessFailureLoading(state) {
            state.ProjectLoading = null
            state.ProjectError = null
            state.ProjectSucess = null
        },
        postProjectData(state, action:PayloadAction) {
            state.ProjectLoading = true
            state.ProjectError = null
            state.ProjectSucess = null
        },
        postProjectSuccess(state, action: PayloadAction<any>) {
            state.ProjectSucess = true
            state.ProjectLoading = false
            state.ProjectError = null
        },
        postProjectFailure(state, action: PayloadAction<any>) {
            state.ProjectSucess = action.payload.isSucess
            state.ProjectLoading = false
            state.ProjectError = action.payload
        }
    },
})
export const { postProjectData,postProjectFailure,postProjectSuccess, resetProjectSuccessFailureLoading } = addProjectSlice.actions
export default addProjectSlice.reducer
