import { GET } from '@api/ApiService'
import { GannChartDataParams } from '@dashboard/store/GannChartData/GannChartData.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
    getGannChartData: (sendParams: GannChartDataParams) =>
        `${BASE_URL}/api/GanttChart?locationId=${sendParams?.locationId}&projectNumber=${sendParams?.projectNumber}`,
}

const GannChartService = {
    getGannChartData: async (sendParams: GannChartDataParams) => {
        return GET(urlFactory.getGannChartData(sendParams))
    },
    
}

export default GannChartService
