import { GET } from '@api/ApiService'
import { ProgressSubLederParams } from '@dashboard/store/ProgressSubLedger/ProgressSubLedger.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getProgressSubLedgerData: (sendParams:ProgressSubLederParams) => `${BASE_URL}/api/ProgressBars/SubLedgers?projectNumber=${sendParams.ProjectId}&ledger_code=${sendParams.Ledgercode}&isParent=${sendParams?.IsParent}&locationId=${sendParams?.locationId}&isCredit=${sendParams?.isCredit}`
}

const ProgressSubLedgerService = {
    getProgressSubLedgerData: async (sendParams:ProgressSubLederParams) => {
        return GET(urlFactory.getProgressSubLedgerData(sendParams));
    },

}

export default ProgressSubLedgerService