import { GET, PUT } from '@api/ApiService'
import { NodesParam, NodesEditParam } from '@app-types/NodesResponses.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = 'https://nsldev.nimble.expert/ZwanenburgAPI'
const urlFactory = {
    getNodesData: (sendParams: NodesParam) =>
        `${BASE_URL}/api/Phases/Timeline?projectNumber=${sendParams.projectNumber}&locationId=${sendParams.locationId}&IsParent=${sendParams.IsParent}`,
    editNodesData: (sendParams: NodesEditParam) =>
        `${BASE_URL}/api/Phases?PhaseId=${sendParams.PhaseId}&PhaseTypeId=${sendParams.PhaseTypeId}&IsParent=${sendParams.IsParent}`,
}

const NodesService = {
    getNodesData: async (sendParams: NodesParam) => {
        return GET(urlFactory.getNodesData(sendParams))
    },

    editNodesData: async (sendParams: NodesEditParam) => {
        return PUT(urlFactory.editNodesData(sendParams), {})
    },
}

export default NodesService
