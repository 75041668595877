import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface InitState {
    StikoLoading: null | boolean;
    StikoSucess: null | boolean;
    StikoError: any
}

const initstage: InitState = {
    StikoLoading: null,
    StikoSucess: null,
    StikoError:null
  
}

const addStikoSlice = createSlice({
    name: 'addStikoSlice',
    initialState: initstage,
    reducers: {
        resetStikoSuccessFailureLoading(state) {
            state.StikoLoading = null
            state.StikoError = null
            state.StikoSucess = null
        },
        postStikoData(state, action:PayloadAction) {
            state.StikoLoading = true
            state.StikoError = null
            state.StikoSucess = null
        },
        postStikoSuccess(state, action: PayloadAction<any>) {
            state.StikoSucess = true
            state.StikoLoading = false
            state.StikoError = null
        },
        postStikoFailure(state, action: PayloadAction<any>) {
            state.StikoSucess = action.payload.isSucess
            state.StikoLoading = false
            state.StikoError = action.payload
        }
    },
})
export const { postStikoData,postStikoFailure,postStikoSuccess, resetStikoSuccessFailureLoading } = addStikoSlice.actions
export default addStikoSlice.reducer
