import { GET } from '@api/ApiService'
import { ProgressLedgerCreditParams } from '@dashboard/store/ProgressLedgerCredit/ProgressLedgerCredit.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getProgressLedgerCreditData: (sendParams:ProgressLedgerCreditParams) => `${BASE_URL}/api/ProgressBars/Ledgers?projectNumber=${sendParams.projectNumber}&locationId=${sendParams.locationId}&isParent=${sendParams?.IsParent}&isCredit=${sendParams?.isCredit}`
}

const ProgressLedgerCreditService = {
    getProgressLedgerCreditData: async (sendParams:ProgressLedgerCreditParams) => {
        return GET(urlFactory.getProgressLedgerCreditData(sendParams));
    },

}

export default ProgressLedgerCreditService