import { MutatiesResponse } from '@app-types/MutatiesResponse.types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { MutatiesParams, Mutatiestypes } from './Mutaties.types'

const initstage: Mutatiestypes = {
    MutatiesLoading: false,
    MutatiesData: null,
    MutatiesError: null,

    ExcelMutatiesLoading: false,
    ExcelMutatiesData: null,
    ExcelMutatiesError: null,
}

const MutatiesSlice = createSlice({
    name: 'MutatiesSlice',
    initialState: initstage,
    reducers: {
        getMutatiesData(state, action: PayloadAction<MutatiesParams>) {
            state.MutatiesLoading = true
            state.MutatiesError = null
        },
        getMutatiesSuccess(state, action: PayloadAction<MutatiesResponse>) {
            state.MutatiesData = action.payload
            state.MutatiesLoading = false
            state.MutatiesError = null
        },
        getMutatiesFailure(state, action: PayloadAction<any>) {},

        getExcelMutaties(state, action: PayloadAction<MutatiesParams>) {
            state.ExcelMutatiesLoading = true
            state.ExcelMutatiesError = null
        },
        getExcelMutatiesDataSuccess(state, action: PayloadAction<string>) {
            state.ExcelMutatiesData = action.payload
            state.ExcelMutatiesLoading = false
            state.ExcelMutatiesError = null
        },
        getExcelMutatiesFailure(state, action: PayloadAction<any>) {
            state.ExcelMutatiesError = action.payload
        },
    },
})
export const {
    getMutatiesData,
    getMutatiesSuccess,
    getMutatiesFailure,
    getExcelMutaties,
    getExcelMutatiesDataSuccess,
    getExcelMutatiesFailure,
} = MutatiesSlice.actions
export default MutatiesSlice.reducer
