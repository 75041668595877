import authenticationSaga from '@authentication/store/authentication.saga'
import DashboardSaga from '@dashboard/store/Dashboard.saga'
import { all } from '@redux-saga/core/effects'
import UserSaga from '@users/store/user.saga'

function* rootSaga(): any {
    yield all([...authenticationSaga, ...DashboardSaga, ...UserSaga])
}

export default rootSaga
