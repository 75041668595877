import authenticationReducer from '@authentication/store/authentication.slice'
import ExcelFileReducer from '@dashboard/store/ExcelFile/ExcelFile.slice'
import GannChartDataReducer from '@dashboard/store/GannChartData/GannChartData.slice'
import GantCreateOrEditReducer from '@dashboard/store/GantCreateOrEdit/GantCreateOrEdit.slice'
import LocationReducer from '@dashboard/store/Location/Location.slice'
import ProjectListReducer from '@dashboard/store/Project/ProjectsList.slice'
import StikoExcelReducer from '@dashboard/store/StikoExcel/StikoExcel.slice'
import AddStikoSlice from '@dashboard/store/StikoPage/AddStiko.slice'
import StikoPageReducer from '@dashboard/store/StikoPage/StikoPage.slice'
import StikoReducer from '@dashboard/store/StikoTable/StikoTable.slice'
import { configureStore } from '@reduxjs/toolkit'
// import Reactotron from '@config/Reactotron.config'
import rootSaga from '@store/saga'
import { combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'

import CockpitChartReducer from '../modules/dashboard/store/CockpitChart/CockpitChart.slice'
import CockpitLeaderReducer from '../modules/dashboard/store/CockpitLeader/CockpitLeader.slice'
import CockpitProjectsReducer from '../modules/dashboard/store/CockpitProjects/CockpitProjects.slice'
import faseringReducer from '../modules/dashboard/store/Fasering/Fasering.slice'
import GaugeReducer from '../modules/dashboard/store/Gauge/Gauge.slice'
import LineChartReducer from '../modules/dashboard/store/LineChart/LineChart.slice'
import mapReducer from '../modules/dashboard/store/Maps/Maps.slice'
import MutatiesReducer from '../modules/dashboard/store/Mutaties/Mutaties.slice'
import NodesReducer from '../modules/dashboard/store/Nodes/Nodes.slice'
import nodesReducer from '../modules/dashboard/store/NodesMain/NodesMainSlice'
import PieChartReducer from '../modules/dashboard/store/PieChart/PieChart.slice'
import ProgressledgerReducer from '../modules/dashboard/store/ProgressLedger/ProgressLedger.slice'
import ProgressledgerCreditReducer from '../modules/dashboard/store/ProgressLedgerCredit/ProgressLedgerCredit.slice'
import ProgressSubledgerReducer from '../modules/dashboard/store/ProgressSubLedger/ProgressSubLeger.slice'
import addProjectReducer from '../modules/dashboard/store/Project/AddProject.slice'
import projectReducer from '../modules/dashboard/store/Project/Project.slice'
import ProjectDetailsReducer from '../modules/dashboard/store/ProjectDetailsHeader/ProjectDetails.slice'
import statsReducer from '../modules/dashboard/store/StatisticsCards/StatsCards.slice'
import YearReducer from '../modules/dashboard/store/Year/Year.slice'
import UserReducer from '../modules/users/store/user.slice'

import generalActionData from './generalStore/general.slice'

// const NODE_ENV = process.env.NODE_ENV
const sagaMiddleware = createSagaMiddleware()

const middlewares: any[] = [sagaMiddleware]

const reducers = combineReducers({
    authentication: authenticationReducer,
    Nodes: NodesReducer,
    statsCards: statsReducer,
    generalActionData,
    ProjectTab: projectReducer,
    projectDetailsHeader: ProjectDetailsReducer,
    ProgressLeadgerData: ProgressledgerReducer,
    ProgressLeadgerCreditData: ProgressledgerCreditReducer,
    ProgressSubLeadgerData: ProgressSubledgerReducer,
    NodesData: nodesReducer,
    FaseringTab: faseringReducer,
    MapTab: mapReducer,
    GaugeData: GaugeReducer,
    MutatiesData: MutatiesReducer,
    CockpitProjectsData: CockpitProjectsReducer,
    YearData: YearReducer,
    CockpitLeader: CockpitLeaderReducer,
    LineChartData: LineChartReducer,
    CockpitChartData: CockpitChartReducer,
    PieChart: PieChartReducer,
    ExcelFile: ExcelFileReducer,
    addProject: addProjectReducer,
    AddStiko: AddStikoSlice,
    Location: LocationReducer,
    StikoData: StikoReducer,
    StikoExcelData: StikoExcelReducer,
    StikoPage: StikoPageReducer,
    ProjectListReducer,
    GantCreateOrEdit: GantCreateOrEditReducer,
    GannChartData: GannChartDataReducer,
    user: UserReducer,
})

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: middlewares,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // enhancers: NODE_ENV === 'development' ? [Reactotron.createEnhancer!()] : undefined,
})

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
