import { GET } from '@api/ApiService'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
    getYearData: () => `${BASE_URL}/api/CockPit/Years`
}

const YearService = {
    getYearData: async () => {
        return GET(urlFactory.getYearData());
    },

}

export default YearService