import { GET } from '@api/ApiService'
// import { ExcelTypesParams } from '@dashboard/store/ExcelFile/ExcelFile.types'
import { StikoParams } from '@dashboard/store/StikoTable/StikoTable.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    getStikoExcelData: (sendParams: StikoParams) => {
        let dynamicURL = `${BASE_URL}/api/Stiko/ExportStikoAsBase64`
        const queryParameters = []

        if (sendParams.SearchQuery !== '') {
            queryParameters.push(`SearchQuery=${sendParams.SearchQuery}`)
        }

        if (sendParams.ProjectNumber !== '') {
            queryParameters.push(`ProjectNumber=${sendParams.ProjectNumber}`)
        }

        if (sendParams.locationId !== '') {
            queryParameters.push(`locationId=${sendParams.locationId}`)
        }

        if (sendParams.Version !== '') {
            queryParameters.push(`Version=${sendParams.Version}`)
        }

        if (sendParams.LastModified !== '') {
            queryParameters.push(`LastModified=${sendParams.LastModified}`)
        }

        if (sendParams.TotalCost !== '') {
            queryParameters.push(`TotalCost=${sendParams.TotalCost}`)
        }

        if (sendParams.UserName !== '') {
            queryParameters.push(`UserName=${sendParams.UserName}`)
        }
        
        if (queryParameters.length > 0) {
            dynamicURL += `?${queryParameters.join('&')}`
        }

        return dynamicURL
    },
}

const StikoExcelService = {

    getStikoExcelData: async (sendParams: StikoParams) => {
        return GET(urlFactory.getStikoExcelData(sendParams))
    },
}
export default StikoExcelService