import { GET } from '@api/ApiService'
import { getUserParams } from '@app-types/FaseringTabResponse.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"
// const urlFactory = {
//    getProjectTabData:( sendParams:getUserParams) => `${BASE_URL}/api/Project?SearchQuery=${sendParams.SearchQuery}&ProjectNumber=${sendParams.ProjectNumber}&ProjectLeader=${sendParams.ProjectLeader}&ProjectName=${sendParams.ProjectName}&Fase1=${sendParams.Fase1}&Fase2=${sendParams.Fase2}&Fase3=${sendParams.Fase3}&PageNumber=${sendParams.PageNumber}&PageSize=${sendParams.PageSize}`,
// }
const urlFactory = {
    getFaseringTabData: (sendParams:getUserParams) => {
      let dynamicURL = `${BASE_URL}/api/Phases/PhaseOverview`;
      const queryParameters = [];
  
      if (sendParams.SearchQuery!==null) {
        queryParameters.push(`SearchQuery=${sendParams.SearchQuery}`);
      }
      if (sendParams.PageNumber) {
        queryParameters.push(`PageNumber=${sendParams.PageNumber}`);
      }
  
      if (sendParams.PageSize) {
        queryParameters.push(`PageSize=${sendParams.PageSize}`);
      }
  
      if (queryParameters.length > 0) {
        dynamicURL += `?${queryParameters.join('&')}`;
      }
  
      return dynamicURL;
    }
  };

const FaseringTabService = {

    getFaseringTabData: async ( sendParams:getUserParams) => {
  
        return GET(urlFactory.getFaseringTabData(sendParams))
    },


}

export default FaseringTabService