import { getUserParams,ProjectMainDataResponse } from '@app-types/ProjectTabResponse.types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { ProjectDataType } from './Project.types'

const initstage: ProjectDataType = {
    ProjectLoading: false,
    ProjectData: null,
    ProjectError: null,
    hasFormErrors: false,
    hasAllRequiredFields: false
}

const ProjectSlice = createSlice({
    name: 'ProjectSlice',
    initialState: initstage,
    reducers: {
        getProjectData(state, action: PayloadAction<getUserParams>) {
            state.ProjectLoading = true
            state.ProjectError = null
        },
        getProjectDataSuccess(state, action: PayloadAction<ProjectMainDataResponse>) {
            state.ProjectData = action.payload
            state.ProjectLoading = false
            state.ProjectError = null
        },
        getProjectDataFailure(state, action: PayloadAction<any>) {},
        setHasFormErrors(state, action: PayloadAction<boolean>) {
            state.hasFormErrors = action.payload
        },
        setHasAllRequiredFields(state, action: PayloadAction<boolean>) {
            state.hasAllRequiredFields = action.payload
        }
    },
})
export const { getProjectData, getProjectDataSuccess, getProjectDataFailure, setHasFormErrors, setHasAllRequiredFields } = ProjectSlice.actions
export default ProjectSlice.reducer
