import { GannChartDataResponse } from '@app-types/GannChartDataResponse'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { GannChartDataParams, GannChartDataTypes } from './GannChartData.types'

const initstage: GannChartDataTypes = {
    GannChartDataLoading: false,
    GannChartData: null,
    GannChartDataError: null,
}

const GannChartDataSlice = createSlice({
    name: 'GannChartData',
    initialState: initstage,
    reducers: {
        getGannChartData(state, action: PayloadAction<GannChartDataParams>) {
            state.GannChartDataLoading = true
            state.GannChartDataError = null
        },
        getGannChartDataSuccess(state, action: PayloadAction<GannChartDataResponse>) {
            state.GannChartData = action.payload
            state.GannChartDataLoading = false
            state.GannChartDataError = null
        },
        getGannChartDataFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getGannChartData, getGannChartDataSuccess, getGannChartDataFailure } = GannChartDataSlice.actions
export default GannChartDataSlice.reducer
