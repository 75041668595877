import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CockpitProjectsTypes } from './CockpitProjects.types'
import { CockpitProjectsResponse } from '@app-types/CockpitProjectsResponse.types'

const initstage: CockpitProjectsTypes = {
    CockpitProjectsLoading: false,
    CockpitProjectsData: null,
    CockpitProjectsError: null,

}

const CockpitProjectsSlice = createSlice({
    name: 'CockpitProjects',
    initialState: initstage,
    reducers: {
        getCockpitProjectsData(state, action: PayloadAction) {
            state.CockpitProjectsLoading = true
            state.CockpitProjectsError = null
        },
        getCockpitProjectsSuccess(state, action: PayloadAction<CockpitProjectsResponse[]>) {
            state.CockpitProjectsData = action.payload
            state.CockpitProjectsLoading = false
            state.CockpitProjectsError = null
        },
        getCockpitProjectsFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getCockpitProjectsData, getCockpitProjectsSuccess, getCockpitProjectsFailure } = CockpitProjectsSlice.actions
export default CockpitProjectsSlice.reducer
