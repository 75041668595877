import { GET } from '@api/ApiService'
import { CockpitChartParams } from '@dashboard/store/CockpitChart/CockpitChart.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = 'https://nsldev.nimble.expert/ZwanenburgAPI'

const urlFactory = {

    getCockpitChartData: (sendParams: CockpitChartParams) => {
        let dynamicURL = `${BASE_URL}/api/CockPit/Chart`
        const queryParameters = []

        sendParams?.projectId?.forEach((element: any) => {
            queryParameters.push(`projectId=${element}`)
        })

        sendParams?.leader?.forEach((element: any) => {
            queryParameters.push(`leader=${element.projectLeaderName}`)
        })

        // if (sendParams.leader !== '') {
        //     queryParameters.push(`leader=${sendParams.leader}`)
        // }

        if (sendParams.Year) {
            queryParameters.push(`Year=${sendParams.Year}`)
        }

        if (sendParams.ReportFor) {
            queryParameters.push(`ReportFor=${sendParams.ReportFor}`)
        }

        if (queryParameters.length > 0) {
            dynamicURL += `?${queryParameters.join('&')}`
        }
        
        return dynamicURL
    },
}

const CockpitChartService = {
    getCockpitChartData: async (sendParams: CockpitChartParams) => {
        return GET(urlFactory.getCockpitChartData(sendParams))
    },
}

export default CockpitChartService
