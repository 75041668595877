import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProgressLedgerCreditTypes, ProgressLedgerCreditParams } from './ProgressLedgerCredit.types'
import { ProgressLedgerResponse } from '@app-types/ProgressLedgerResponse'
import { ProgressSubLedgerResponse } from '@app-types/ProgressSubLedgerResponse.types'

const initstage: ProgressLedgerCreditTypes = {
    ProgressLedgerCreditLoading: false,
    ProgressLedgerCreditData: null,
    ProgressLedgerCreditError: null,
    // "isSucess": true,
    // "message": "Project header fetched successfully!!!"
}

const ProgressLedgerCreditDetailsSlice = createSlice({
    name: 'ProgressLedgerCreditDetailsSlice',
    initialState: initstage,
    reducers: {
        getProgressLedgerCreditData(state, action: PayloadAction<ProgressLedgerCreditParams>) {
            state.ProgressLedgerCreditLoading = true
            state.ProgressLedgerCreditError = null
        },
        getProgressLedgerCreditSuccess(state, action: PayloadAction<ProgressLedgerResponse>) {
            state.ProgressLedgerCreditData = action.payload
            state.ProgressLedgerCreditLoading = false
            state.ProgressLedgerCreditError = null
        },
        getProgressLedgerCreditFailure(state, action: PayloadAction<any>) {},
        setProgressLedgerCreditData(state, action: PayloadAction<ProgressSubLedgerResponse>) {
            state.ProgressLedgerCreditData?._Data?.forEach((item) => {
                const subLedgerdata = action.payload
                const _Data = [...subLedgerdata._Data]
                const findarry = subLedgerdata?._Data.find((itemSub) => itemSub.LedgerId === item.code)
                if (findarry) {
                    item._Data = _Data
                }
            })
        },
    },
})
export const { getProgressLedgerCreditData, getProgressLedgerCreditSuccess, getProgressLedgerCreditFailure, setProgressLedgerCreditData } =
ProgressLedgerCreditDetailsSlice.actions
export default ProgressLedgerCreditDetailsSlice.reducer
