import { GET } from '@api/ApiService'
import { getUserParams } from '@app-types/ProjectTabResponse.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    getProjectTabData: (sendParams: getUserParams) => {
        let dynamicURL = `${BASE_URL}/api/Project`
        const queryParameters = []

        if (sendParams.SearchQuery !== '') {
            queryParameters.push(`SearchQuery=${sendParams.SearchQuery}`)
        }

        if (sendParams.ProjectNumber !== '') {
            queryParameters.push(`ProjectNumber=${sendParams.ProjectNumber}`)
        }

        if (sendParams.ProjectLeader !== '') {
            queryParameters.push(`ProjectLeader=${sendParams.ProjectLeader}`)
        }

        if (sendParams.ProjectName !== '') {
            queryParameters.push(`ProjectName=${sendParams.ProjectName}`)
        }

        if (sendParams.Fase1 !== '') {
            queryParameters.push(`Fase1=${sendParams.Fase1}`)
        }

        if (sendParams.Fase2 !== '') {
            queryParameters.push(`Fase2=${sendParams.Fase2}`)
        }

        if (sendParams.Fase3 !== '') {
            queryParameters.push(`Fase3=${sendParams.Fase3}`)
        }

        if (sendParams.TotalCost !== '') {
            queryParameters.push(`TotalCostFormatted=${sendParams.TotalCost}`)
        }

        if (sendParams.ishidden !== null) {
            queryParameters.push(`ishidden=${sendParams.ishidden}`)
        }

        if (sendParams.PageNumber) {
            queryParameters.push(`PageNumber=${sendParams.PageNumber}`)
        }

        if (sendParams.PageSize) {
            queryParameters.push(`PageSize=${sendParams.PageSize}`)
        }

        if (queryParameters.length > 0) {
            dynamicURL += `?${queryParameters.join('&')}`
        }

        return dynamicURL
    },
}

const ProjectTabService = {
    getProjectTabData: async (sendParams: getUserParams) => {
        return GET(urlFactory.getProjectTabData(sendParams))
    },
}

export default ProjectTabService
