import { GET } from '@api/ApiService'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
    getLocationData: () => `${BASE_URL}/api/Project/Locations`
}

const LocationService = {
    getLocationData: async () => {
        return GET(urlFactory.getLocationData());
    },

}

export default LocationService