import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { yearTypes } from './Year.types'

const initstage: yearTypes = {
    yearLoading: false,
    yearData: null,
    yearError: null,
}

const YearSlice = createSlice({
    name: 'YearSlice',
    initialState: initstage,
    reducers: {
        getYearData(state, action: PayloadAction) {
            state.yearLoading = true
            state.yearError = null
        },
        getYearSuccess(state, action: PayloadAction<[]>) {
            state.yearData = action.payload
            state.yearLoading = false
            state.yearError = null
        },
        getYearFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getYearData, getYearSuccess, getYearFailure } = YearSlice.actions
export default YearSlice.reducer
