import { ProjectCardsResponse } from '@app-types/ProjectCardResponse.types'
import {GetProjectLeaderType}  from '@app-types/ProjectCreationType'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { ProjectCardTypes, projectDetailParams } from './ProjectDetails.types'

const initstage: ProjectCardTypes = {
    projectDetailsCardsLoading: false,
    projectDetailsCardsData: null,
    projectDetailsCardsError: null,
    // "isSucess": true,
    // "message": "Project header fetched successfully!!!"
    projectLeaderdata : null,
    projectLeaderLoading: false,
    projectLeaderError: null
}

const ProjectDetailsSlice = createSlice({
    name: 'ProjectDetailsSlice',
    initialState: initstage,
    reducers: {
        getProjectDetailsData(state, action: PayloadAction<projectDetailParams>) {
            state.projectDetailsCardsLoading = true
            state.projectDetailsCardsError = null
        },
        getProjectDetailsSuccess(state, action: PayloadAction<ProjectCardsResponse>) {
            state.projectDetailsCardsData = action.payload
            state.projectDetailsCardsLoading = false
            state.projectDetailsCardsError = null
        },
        getProjectDetailsFailure(state, action: PayloadAction<any>) {},


        getProjectLeaderData(state) {
            state.projectLeaderLoading = true
            state.projectLeaderError = null
        },
       getProjectLeaderDataSuccess(state, action: PayloadAction<GetProjectLeaderType[]>) {
            state.projectLeaderdata = action.payload
            state.projectLeaderLoading = false
            state.projectLeaderError = null
        },
        getProjectLeaderDataFailure(state, action: PayloadAction<any>) {
            state.projectLeaderLoading = false
            state.projectLeaderError = action.payload
        },
    },
})
export const { getProjectDetailsData, getProjectDetailsSuccess, getProjectDetailsFailure, getProjectLeaderData, getProjectLeaderDataSuccess,getProjectLeaderDataFailure  } = ProjectDetailsSlice.actions
export default ProjectDetailsSlice.reducer
