import {
    PostTimeLineNodeParams,
    UpdateDragorMoveTimelineNodeParams,
} from '@dashboard/store/GantCreateOrEdit/GantCreateOrEdit.types'

import { DELETE, GET, POST, PUT } from './ApiService'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    getLeaderNames: () => `${BASE_URL}/api/CockPit/LeaderName`,
    postTimeLineNode: (locationId: string, projectNumber: string, subLedgerCode: number) =>
        `${BASE_URL}/api/GanttChart/AddNodes?locationId=${locationId}&projectNumber=${projectNumber}&subLedgerCode=${subLedgerCode}`,
    editTimeLineNode: (locationId: string, projectNumber: string, subLedgerCode: number) =>
        `${BASE_URL}/api/GanttChart/EditNodes?locationId=${locationId}&projectNumber=${projectNumber}&subLedgerCode=${subLedgerCode}`,
    deleteTimeLine: (timelineNodeId: number) =>
        `${BASE_URL}/api/GanttChart/DeleteNodes?timeline_node_id=${timelineNodeId}`,
    updateDragorMoveTimelineNode: (timelineid: number, dateFrom: string, dateTo: string) =>
        `${BASE_URL}/api/GanttChart/UpdateNodes?timeline_node_id=${timelineid}&date_from=${dateFrom}&date_to=${dateTo}`,
}

const GantCreateOrEditService = {
    getLeaderNames: async () => {
        return GET(urlFactory.getLeaderNames())
    },
    postTimeLineNode: async (params: PostTimeLineNodeParams) => {
        return POST(
            urlFactory.postTimeLineNode(params.locationId, params.projectNumber, params.subLedgerCode),
            params.body
        )
    },
    editTimeLineNode: async (params: PostTimeLineNodeParams) => {
        return PUT(
            urlFactory.editTimeLineNode(params.locationId, params.projectNumber, params.subLedgerCode),
            params.body
        )
    },
    deleteTimeLine: async (timeLineNodeId: number) => {
        return DELETE(urlFactory.deleteTimeLine(timeLineNodeId))
    },
    updateDragorMoveTimelineNode: async (data: UpdateDragorMoveTimelineNodeParams) => {
        return PUT(urlFactory.updateDragorMoveTimelineNode(data.timelineid, data.dateFrom, data.dateTo), {})
    },
}

export default GantCreateOrEditService
