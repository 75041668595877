import AuthenticationService from '@api/AuthenticationService'
import { GetTokenResponse, GetUserInfoResponse } from '@app-types/Responses.types'
import {
    getToken,
    getTokenFailure,
    getTokenSuccess,
    userInfoHandler,
    userInfoHandlerFailure,
    userInfoHandlerSuccess,
} from '@authentication/store/authentication.slice'
import { handleExistingAuthentication,handleNotification } from '@store/generalStore/general.slice'
import { AnyAction } from 'redux'
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

function* watchGetToken(
    action: ReturnType<typeof getToken>
): Generator<CallEffect<GetTokenResponse> | PutEffect<AnyAction>, void, GetTokenResponse> {
    const { redirectUrl, code } = action.payload
    try {
        const response = yield call(AuthenticationService.getToken, redirectUrl, code)
        yield put(getTokenSuccess(response))
        yield put(userInfoHandler())
    } catch (error) {
        yield put(getTokenFailure(error))
    }
}

function* watchUserInfoHandler(
    action: ReturnType<typeof userInfoHandler>
): Generator<CallEffect<GetUserInfoResponse> | PutEffect<AnyAction>, void, GetUserInfoResponse> {
    try {
        const response = yield call(AuthenticationService.getUserinfo)
        yield put(userInfoHandlerSuccess(response))

        if (!response?.isFound) {
            yield put(
                handleNotification({
                    variant: 'error',
                    message: 'Oh snap!',
                    info: 'You dont have Permissions',
                })
            )
        } else {
            yield put(handleExistingAuthentication(200))
        }
    } catch (error) {
        yield put(userInfoHandlerFailure(error))
        yield put(
            handleNotification({
                variant: 'error',
                message: 'Oh snap!',
                info: 'User not able to login',
            })
        )
    }
}

const authenticationSaga = [takeLatest(getToken, watchGetToken), takeLatest(userInfoHandler, watchUserInfoHandler)]

export default authenticationSaga
