import { LeaderNameType } from '@app-types/GantCreateOrEditResponse.types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import {
    GantCreateOrEditTypes,
    PostResponseType,
    PostTimeLineNodeParams,
    UpdateDragorMoveTimelineNodeParams,
} from './GantCreateOrEdit.types'

const initstage: GantCreateOrEditTypes = {
    getLeaderNamesLoading: false,
    getLeaderNamesData: null,
    getLeaderNamesError: null,

    postTimeLineNodeLoading: false,
    postTimeLineNodeSuccess: false,
    postTimeLineNodeMessage: '',
    postTimeLineNodeHasError: false,

    editTimeLineNodeLoading: false,
    editTimeLineNodeSuccess: false,
    editTimeLineNodeHasError: false,

    deleteTimeLineLoading: false,
    deleteTimeLineSuccess: false,
    deleteTimeLineHasError: false,

    updateDragorMoveTimeLineNodeloading: false,
    updateDragorMoveTimeLineNodeData: null,
    updateDragorMoveTimeLineNodeError: false,
}

const GantCreateOrEditSlice = createSlice({
    name: 'GantCreateOrEdit',
    initialState: initstage,
    reducers: {
        getLeaderNames(state) {
            state.getLeaderNamesLoading = true
            state.getLeaderNamesError = null
        },
        getLeaderNamesSuccess(state, action: PayloadAction<LeaderNameType[]>) {
            state.getLeaderNamesData = action.payload
            state.getLeaderNamesLoading = false
            state.getLeaderNamesError = null
        },
        getLeaderNamesFailure(state, action: PayloadAction<any>) {
            state.getLeaderNamesLoading = false
            state.getLeaderNamesError = action.payload
        },
        postTimeLineNode(state, action: PayloadAction<PostTimeLineNodeParams>) {
            state.postTimeLineNodeLoading = true
            state.postTimeLineNodeHasError = false
            state.postTimeLineNodeSuccess = false
        },
        postTimeLineNodeSuccess(state, action: PayloadAction<PostResponseType>) {
            if (action.payload.isSucess) {
                state.postTimeLineNodeSuccess = true
            }
            state.postTimeLineNodeMessage = action.payload.message
            state.postTimeLineNodeLoading = false
        },
        postTimeLineNodeFailure(state, action) {
            state.postTimeLineNodeLoading = false
            state.postTimeLineNodeMessage = action.payload
            state.postTimeLineNodeHasError = true
        },
        resetPostTimeLineNode(state) {
            state.postTimeLineNodeLoading = false
            state.postTimeLineNodeSuccess = false
            state.postTimeLineNodeHasError = false
        },
        editTimeLineNode(state, action: PayloadAction<PostTimeLineNodeParams>) {
            state.editTimeLineNodeLoading = true
            state.editTimeLineNodeSuccess = false
            state.editTimeLineNodeHasError = false
        },
        editTimeLineNodeSuccess(state, action: PayloadAction<PostResponseType>) {
            if (action.payload.isSucess) {
                state.editTimeLineNodeSuccess = true
            }
            state.editTimeLineNodeLoading = false
            state.postTimeLineNodeMessage = action.payload.message
        },
        editTimeLineNodeFailure(state, action) {
            state.editTimeLineNodeLoading = false
            state.editTimeLineNodeHasError = true
            state.postTimeLineNodeMessage = action.payload
        },
        resetEditTimeLineNode(state) {
            state.editTimeLineNodeLoading = false
            state.editTimeLineNodeSuccess = false
            state.editTimeLineNodeHasError = false
        },
        deleteTimeLine(state, action: PayloadAction<number>) {
            state.deleteTimeLineLoading = true
            state.deleteTimeLineSuccess = false
            state.deleteTimeLineHasError = false
        },
        deleteTimeLineSuccess(state, action: PayloadAction<PostResponseType>) {
            if (action.payload.isSucess) {
                state.deleteTimeLineSuccess = true
            }
            state.deleteTimeLineLoading = false
            state.postTimeLineNodeMessage = action.payload.message
        },
        deleteTimeLineFailure(state) {
            state.deleteTimeLineLoading = false
            state.deleteTimeLineHasError = true
        },
        resetDeleteTimeLine(state) {
            state.deleteTimeLineLoading = false
            state.deleteTimeLineSuccess = false
            state.deleteTimeLineHasError = false
        },

        updateDragorMoveTimelineNode(state, action: PayloadAction<UpdateDragorMoveTimelineNodeParams>) {
            state.updateDragorMoveTimeLineNodeloading = true
            state.updateDragorMoveTimeLineNodeError = false
        },
        updateDragorMoveTimelineNodeSuccess(state, action: PayloadAction<any>) {
            state.updateDragorMoveTimeLineNodeData = action.payload
            state.updateDragorMoveTimeLineNodeloading = false
            state.updateDragorMoveTimeLineNodeError = false
        },
        updtaeDragorMoveTimelineNodeFailure(state, action) {
            state.updateDragorMoveTimeLineNodeloading = false
            state.updateDragorMoveTimeLineNodeError = true
            state.postTimeLineNodeMessage = action.payload
        },
    },
})
export const {
    getLeaderNames,
    getLeaderNamesSuccess,
    getLeaderNamesFailure,
    postTimeLineNode,
    postTimeLineNodeSuccess,
    postTimeLineNodeFailure,
    resetPostTimeLineNode,
    editTimeLineNode,
    editTimeLineNodeSuccess,
    editTimeLineNodeFailure,
    resetEditTimeLineNode,
    deleteTimeLine,
    deleteTimeLineSuccess,
    deleteTimeLineFailure,
    resetDeleteTimeLine,
    updateDragorMoveTimelineNode,
    updateDragorMoveTimelineNodeSuccess,
    updtaeDragorMoveTimelineNodeFailure,
} = GantCreateOrEditSlice.actions
export default GantCreateOrEditSlice.reducer
