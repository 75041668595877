import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseIcon from '@assets/images/close.svg'
import clientsSVG from '@assets/images/menu/clients.svg'
import feedbackSVG from '@assets/images/menu/feedback.svg'
import logsSVG from '@assets/images/menu/logs.svg'
import user from '@assets/images/menu/user.svg'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { RootState } from '@store/store'
import { lighten } from 'polished'

interface MainMenuProps {
    open: boolean
    mobileMenuClick?: () => void
    isMobile?: boolean
}

const MainMenu: React.FC<MainMenuProps> = ({ open, mobileMenuClick, isMobile }) => {
    const MenuItems = [
        {
            label: 'Home',
            icon: clientsSVG,
            route: '/home',
        },
        {
            label: 'Nieuw project',
            icon: logsSVG,
            route: '/nieuwproject',
        },
        {
            label: 'Charts',
            icon: feedbackSVG,
            route: '/feedback',
        },
        {
            label: 'Gebruikers',
            icon: user,
            route: '/userPage',
        },
    ]
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedMenu, setSelectedMenu] = useState('Home')
    const [modifyMenu, setModifyMenu] = useState<any>()

    const userInfo = useSelector((state: RootState) => state.authentication.getUserInfoData)

  

    useEffect(() => {
        const idRole = userInfo?.user?.isAdmin
        if (idRole !== 1) {
            const temp = MenuItems.filter((item) => item.label !== 'Gebruikers')
            setModifyMenu(temp)
        } else {
            setModifyMenu(MenuItems)
        }
    }, [userInfo])

    useEffect(() => {
        if (location.pathname.includes('/home')) {
            setSelectedMenu('Home')
        }
        if (location.pathname.includes('/nieuwproject')) {
            setSelectedMenu('Nieuw project')
        }
        if (location.pathname.includes('/feedback')) {
            setSelectedMenu('Charts')
        }
        if (location.pathname.includes('/userPage')) {
            setSelectedMenu('Gebruikers')
        }
    }, [location])

    const handleClickMenuItem = ({ label, route }: any): void => {
        setSelectedMenu(label)
        mobileMenuClick?.()
        if (route) {
            navigate(route, { replace: true })
        }
        if (label === 'Log out') {
            navigate('/login', { replace: true })
            localStorage.removeItem('console_admin_token')
        }
    }

    const menus = useMemo(() => {
        const MobileMenuItem = MenuItems.filter((item) => item.label !== 'Nieuw project')

        return isMobile ? [...MobileMenuItem, { label: 'Log out', icon: CloseIcon }] : modifyMenu
    }, [isMobile, modifyMenu])

    return (
        <div style={{ height: '100%' }}>
            <List>
                {menus?.map((item: any) => (
                    <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                height: '48px',
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                borderRadius: '5px',
                                marginLeft: '8px',
                                marginRight: '8px',
                                backgroundColor: selectedMenu === item.label ? 'rgba(159, 197, 64, 0.5)' : '#0C1B2A',
                                '&:hover': {
                                    backgroundColor:
                                        selectedMenu === item.label
                                            ? 'rgba(159, 197, 64, 0.5)'
                                            : lighten(0.1, '#0C1B2A'),
                                },
                            }}
                            onClick={() => {
                                handleClickMenuItem(item)
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '#fff',
                                }}
                            >
                                <img src={item.icon} alt={item.label} />
                            </ListItemIcon>
                            <ListItemText
                                primary={item.label}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    fontFamily: 'Montserrat !important',
                                    color: '#FFFFFE',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default MainMenu
