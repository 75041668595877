import { GET } from '@api/ApiService'
import { ProgressSubLederCreditParams } from '@dashboard/store/ProgressSubLedgerCredit/ProgressSubLedgerCredit.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getProgressSubLedgerCreditData: (sendParams:ProgressSubLederCreditParams) => `${BASE_URL}/api/ProgressBars/SubLedgers?projectNumber=${sendParams.ProjectId}&ledger_code=${sendParams.Ledgercode}&isParent=${sendParams?.IsParent}&locationId=${sendParams?.locationId}&isCredit=${sendParams?.isCredit}`
}

const ProgressSubLedgerCreditService = {
    getProgressSubLedgerCreditData: async (sendParams:ProgressSubLederCreditParams) => {
        return GET(urlFactory.getProgressSubLedgerCreditData(sendParams));
    },

}

export default ProgressSubLedgerCreditService