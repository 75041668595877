import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { NodesDataType } from './Nodes.types'
import { NodesRootData, NodesParam, NodesEditParam } from '@app-types/NodesResponses.types'
const initstage: NodesDataType = {
    NodesLoading: false,
    NodesData: null,
    NodesError: null,
}
const NodesMainSlice = createSlice({
    name: 'NodesSlice',
    initialState: initstage,
    reducers: {
        getNodeData(state, action: PayloadAction<NodesParam>) {
            state.NodesLoading = true
            state.NodesError = null
        },
        getNodesDataSuccess(state, action: PayloadAction<NodesRootData[]>) {
            state.NodesLoading = false
            state.NodesData = action.payload
            state.NodesError = null
        },
        getNodesDataFailure(state, action: PayloadAction<any>) {
            state.NodesLoading = true
            state.NodesError = action.payload
        },

        getEditNodeData(state, action: PayloadAction<NodesEditParam>) {
            state.NodesLoading = true
            state.NodesError = null
        },
        getEditNodesDataSuccess(state, action: PayloadAction<NodesRootData[]>) {
            state.NodesLoading = false
            state.NodesError = null
        },
        getEditNodesDataFailure(state, action: PayloadAction<any>) {
            state.NodesLoading = true
            state.NodesError = action.payload
        },
    },
})
export const {
    getNodeData,
    getNodesDataSuccess,
    getNodesDataFailure,
    getEditNodeData,
    getEditNodesDataSuccess,
    getEditNodesDataFailure,
} = NodesMainSlice.actions
export default NodesMainSlice.reducer
