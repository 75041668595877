import { CockpitLeaderResponse } from '@app-types/CockpitLeaderRespose.types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { CockpitLeaderTypes } from './CockpitLeader.types'

const initstage: CockpitLeaderTypes = {
 
    CockpitLeaderLoading: false,
    CockpitLeaderData: null,
    CockpitLeaderError: null,
}

const CockpitLeaderSlice = createSlice({
    name: 'CockpitLeader',
    initialState: initstage,
    reducers: {
        getCockpitLeaderData(state, action: PayloadAction) {
            state.CockpitLeaderLoading = true
            state.CockpitLeaderError = null
        },
        getCockpitLeaderSuccess(state, action: PayloadAction<CockpitLeaderResponse[]>) {
            state.CockpitLeaderData = action.payload
            state.CockpitLeaderLoading = false
            state.CockpitLeaderError = null
        },
        getCockpitLeaderFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getCockpitLeaderData, getCockpitLeaderSuccess, getCockpitLeaderFailure } = CockpitLeaderSlice.actions
export default CockpitLeaderSlice.reducer
