import UserService from '@api/UserService'
import { handleNotification } from '@store/generalStore/general.slice'
import { AnyAction } from 'redux'
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import {
    getUserData,
    getUserFailureData,
    getUserSuccessData,
    handleAddOrEditUser,
    handleAddOrEditUserFailure,
    handleAddOrEditUserSuccess,
    handleDeleteUser,
    handleDeleteUserFailure,
    handleDeleteUserSuccess,
    handleGetUserRole,
    handleUserRoleFailure,
    handleUserRoleSucess,
} from './user.slice'
import { AddOrEditParams, DeleteParams, userDataParams, userDataResponse } from './user.types'

function* watchViewUser(
    action: ReturnType<typeof getUserData>
): Generator<CallEffect<userDataParams> | PutEffect<AnyAction>, void, userDataResponse> {
    try {
        const response = yield call(UserService.getUserData, action.payload)
        yield put(getUserSuccessData(response))
    } catch (error) {
        yield put(getUserFailureData(error))
    }
}

function* watchUserRole(
    action: ReturnType<typeof handleGetUserRole>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const response = yield call(UserService.getUserRoleData)
        yield put(handleUserRoleSucess(response))
    } catch (error) {
        yield put(handleUserRoleFailure(error))
    }
}

function* WatchAddOrEditUser(
    action: ReturnType<typeof handleAddOrEditUser>
): Generator<CallEffect<AddOrEditParams> | PutEffect<AnyAction>, void, any> {
    try {
        const { userDetails, userId } = action.payload
        if (userId === 0) {
            const response = yield call(UserService.addUser, userDetails)
            yield put(handleAddOrEditUserSuccess(response))
        } else {
            const response = yield call(UserService.editUser, userDetails, userId)
            yield put(handleAddOrEditUserSuccess(response))
            yield put(
                handleNotification({
                    variant: 'success',
                    message: 'Well done!',
                    info: 'User successfully updated',
                })
            )
        }
    } catch (error: any) {
        yield put(handleAddOrEditUserFailure(error))
    }
}

function* watchHandleDeleteUser(
    action: ReturnType<typeof handleDeleteUser>
): Generator<CallEffect<DeleteParams> | PutEffect<AnyAction>, void, any> {
    try {
        const response = yield call(UserService?.deleteUser, action.payload)
        yield put(handleDeleteUserSuccess(response))
    } catch (error) {
        yield put(handleDeleteUserFailure(error))
    }
}

const DashboardSaga = [
    takeLatest(getUserData, watchViewUser),
    takeLatest(handleGetUserRole, watchUserRole),
    takeLatest(handleAddOrEditUser, WatchAddOrEditUser),
    takeLatest(handleDeleteUser, watchHandleDeleteUser),
]
export default DashboardSaga
