import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProjectLedgerTypes, projectLedgerParams } from './ProgressLedger.types'
import { ProgressLedgerResponse } from '@app-types/ProgressLedgerResponse'
import { ProgressSubLedgerResponse } from '@app-types/ProgressSubLedgerResponse.types'

const initstage: ProjectLedgerTypes = {
    projectLedgerLoading: false,
    projectLedgerData: null,
    projectLedgerError: null,
    // "isSucess": true,
    // "message": "Project header fetched successfully!!!"
}

const ProjectLedgerDetailsSlice = createSlice({
    name: 'ProjectLedgerDetailsSlice',
    initialState: initstage,
    reducers: {
        getProjectLedgerData(state, action: PayloadAction<projectLedgerParams>) {
            state.projectLedgerLoading = true
            state.projectLedgerError = null
        },
        getProjectLedgerDetailsSuccess(state, action: PayloadAction<ProgressLedgerResponse>) {
            state.projectLedgerData = action.payload
            state.projectLedgerLoading = false
            state.projectLedgerError = null
        },
        getProjectLedgerFailure(state, action: PayloadAction<any>) {},
        setProcessLedgerData(state, action: PayloadAction<ProgressSubLedgerResponse>) {
            state.projectLedgerData?._Data?.forEach((item) => {
                const subLedgerdata = action.payload
                const _Data = [...subLedgerdata._Data]
                const findarry = subLedgerdata?._Data.find((itemSub) => itemSub.LedgerId === item.code)
                if (findarry) {
                    item._Data = _Data
                }
            })
        },
    },
})
export const { getProjectLedgerData, getProjectLedgerDetailsSuccess, getProjectLedgerFailure, setProcessLedgerData } =
    ProjectLedgerDetailsSlice.actions
export default ProjectLedgerDetailsSlice.reducer
