import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
// import { NodesDataType } from './Nodes.types'
// import { NodesMainDataResponse } from '@app-types/NodesResponses.types'
// import { NodesParam } from '@app-types/NodesResponses.types'
const initstage = {
    data: [
        {
            pro: '2022-004',
            num: 'Sneek Graaf Adolfstraat',
            a: [
                { checking: 'completed', nos: 0 },
                { checking: 'progress', nos: 12 },
                { checking: 'progress', nos: 33 },
                { checking: 'notdone', nos: 55 },
                { checking: 'notdone', nos: 59 },
                { checking: 'completed', nos: 44 },
                { checking: 'notdone', nos: 44 },
                { checking: 'progress', nos: 44 },
                { checking: 'notdone', nos: 44 },
                { checking: 'completed', nos: 44 },
                { checking: 'notdone', nos: 44 },
                { checking: 'progress', nos: 44 },
                { checking: 'notdone', nos: 44 },
            ],
            status: 'good main',
            check: 'done main',
            child: [
                {
                    pro: '2022-004-01',
                    num: 'Sneek Graaf Adolfstraat One',
                    a: [
                        { checking: 'notdone', nos: 0 },
                        { checking: 'progress', nos: 12 },
                        { checking: 'progress', nos: 33 },
                        { checking: 'notdone', nos: 55 },
                        { checking: 'notdone', nos: 59 },
                        { checking: 'completed', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                        { checking: 'progress', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                        { checking: 'completed', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                        { checking: 'progress', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                    ],
                    status: 'good child1',
                    check: 'done child1',
                },
                {
                    pro: '2022-004-02',
                    num: 'Sneek Graaf Adolfstraat Two',
                    a: [
                        { checking: 'progress', nos: 0 },
                        { checking: 'progress', nos: 12 },
                        { checking: 'progress', nos: 33 },
                        { checking: 'notdone', nos: 55 },
                        { checking: 'notdone', nos: 59 },
                        { checking: 'completed', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                        { checking: 'progress', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                        { checking: 'completed', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                        { checking: 'progress', nos: 44 },
                        { checking: 'notdone', nos: 44 },
                    ],
                    status: 'good child2',
                    check: 'done child2',
                },
            ],
        },
    ],
    // NodesLoading:false,
    // NodesData:null,
    // NodesError:null,
}
const NodesSlice = createSlice({
    name: 'NodesSlice',
    initialState: initstage,
    reducers: {
        changeData(state, action: PayloadAction<any>) {
            const { selectedValue, aIndex, childIndex, mainIndex } = action.payload
            if (childIndex === null) {
                state.data[mainIndex].a[aIndex].checking = selectedValue
            } else {
                state.data[0].child[childIndex].a[aIndex].checking = selectedValue
            }
        },
    },
})
export const { changeData } = NodesSlice.actions
export default NodesSlice.reducer
