import AddProjectService from '@api/AddProjectService'
import AddStikoService from '@api/AddStikoService'
import CockpitChartService from '@api/CockpitChartService'
import CockpitLeaderService from '@api/CockPitLeaderService'
import CockpitProjectsService from '@api/CockpitProjectsService'
import DashboardService from '@api/DashboardService'
import ExcelService from '@api/ExcelService'
import FaseringTabService from '@api/FaseringTabService'
import GannChartService from '@api/GannChartDataService'
import GantCreateOrEditService from '@api/GantCreateOrEditService'
import GaugeService from '@api/GaugeService'
import LineChartService from '@api/LineChartService'
import LocationService from '@api/LocationService'
import MapTabService from '@api/MapTabService'
import MutatieService from '@api/MutatiesService'
import NodesService from '@api/NodesService'
import PieChartService from '@api/PieChartService'
import ProgressLedgerCreditService from '@api/ProgressLedgerCreditService'
import ProgressSubLedgerCreditService from '@api/ProgressSubLedgerCreditService'
import ProgressSubLedgerService from '@api/ProgressSubLedgerService'
import ProjectDetailsservice from '@api/ProjectDetailsservice'
import ProgressLedgerService from '@api/ProjectLedgerService'
import ProjectListService from '@api/ProjectListService'
import ProjectTabService from '@api/ProjectTabService'
import StikoExcelService from '@api/StikoExcelService'
import StikoPageService from '@api/StikoPageService'
import StikoService from '@api/StikoService'
import YearService from '@api/YearService'
import { CockpitChartResponse } from '@app-types/CockpitChartResponse'
import { CockpitLeaderResponse } from '@app-types/CockpitLeaderRespose.types'
import { CockpitProjectsResponse } from '@app-types/CockpitProjectsResponse.types'
import { FaseringMainDataResponse } from '@app-types/FaseringTabResponse.types'
import { GannChartDataResponse } from '@app-types/GannChartDataResponse'
import { LeaderNameType } from '@app-types/GantCreateOrEditResponse.types'
import { GaugeResponse } from '@app-types/GaugeResponse.types'
import { LineChartResponse } from '@app-types/LineChartResponse.types'
import { MapMainDataResponse } from '@app-types/MapTapResponse.types'
import { MutatiesResponse } from '@app-types/MutatiesResponse.types'
import { NodesRootData } from '@app-types/NodesResponses.types'
import { PieRootData } from '@app-types/PieChartResponse.types'
import { ProgressLedgerResponse } from '@app-types/ProgressLedgerResponse'
import { ProgressSubLedgerResponse } from '@app-types/ProgressSubLedgerResponse.types'
import { ProjectCardsResponse } from '@app-types/ProjectCardResponse.types'
import { GetProjectLeaderType } from '@app-types/ProjectCreationType'
import { ProjectMainDataResponse, ProjectsListResponse } from '@app-types/ProjectTabResponse.types'
import { statsCardsResponse } from '@app-types/StatsResponse.types'
import { stikoPageRespose } from '@app-types/StikoPageResponse.types'
import { stikoResponse } from '@app-types/StikoTableResponse.types'
import { AnyAction } from 'redux'
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import {
    getProjectDetailsData,
    getProjectDetailsFailure,
    getProjectDetailsSuccess,
    getProjectLeaderData,
    getProjectLeaderDataFailure,
    getProjectLeaderDataSuccess,
} from '../store/ProjectDetailsHeader/ProjectDetails.slice'
import { getStatsData, getStatsDataFailure, getStatsDataSuccess } from '../store/StatisticsCards/StatsCards.slice'

import { getCockpitChartData, getCockpitChartFailure, getCockpitChartSuccess } from './CockpitChart/CockpitChart.slice'
import {
    getCockpitLeaderData,
    getCockpitLeaderFailure,
    getCockpitLeaderSuccess,
} from './CockpitLeader/CockpitLeader.slice'
import {
    getCockpitProjectsData,
    getCockpitProjectsFailure,
    getCockpitProjectsSuccess,
} from './CockpitProjects/CockpitProjects.slice'
import { getExcelData, getExcelFailure, getExcelSuccess } from './ExcelFile/ExcelFile.slice'
import { getFaseringData, getFaseringDataFailure, getFaseringDataSuccess } from './Fasering/Fasering.slice'
import { getGannChartData, getGannChartDataFailure, getGannChartDataSuccess } from './GannChartData/GannChartData.slice'
import {
    deleteTimeLine,
    deleteTimeLineFailure,
    deleteTimeLineSuccess,
    editTimeLineNode,
    editTimeLineNodeFailure,
    editTimeLineNodeSuccess,
    getLeaderNames,
    getLeaderNamesFailure,
    getLeaderNamesSuccess,
    postTimeLineNode,
    postTimeLineNodeFailure,
    postTimeLineNodeSuccess,
    updateDragorMoveTimelineNode,
    updateDragorMoveTimelineNodeSuccess,
    updtaeDragorMoveTimelineNodeFailure,
} from './GantCreateOrEdit/GantCreateOrEdit.slice'
import { UpdateDragorMoveTimelineNodeParams } from './GantCreateOrEdit/GantCreateOrEdit.types'
import { getGaugeData, getGaugeFailure, getGaugeSuccess } from './Gauge/Gauge.slice'
import { getLineChartData, getLineChartFailure, getLineChartSuccess } from './LineChart/LineChart.slice'
import { getLocationData, getLocationFailure, getLocationSuccess } from './Location/Location.slice'
import { getMapData, getMapDataFailure, getMapDataSuccess } from './Maps/Maps.slice'
import {
    getExcelMutaties,
    getExcelMutatiesDataSuccess,
    getExcelMutatiesFailure,
    getMutatiesData,
    getMutatiesFailure,
    getMutatiesSuccess,
} from './Mutaties/Mutaties.slice'
import {
    getEditNodeData,
    getEditNodesDataFailure,
    getEditNodesDataSuccess,
    getNodeData,
    getNodesDataFailure,
    getNodesDataSuccess,
} from './NodesMain/NodesMainSlice'
import { getPieChartData, getPieChartDataFailure, getPieChartDataSuccess } from './PieChart/PieChart.slice'
import {
    getProjectLedgerData,
    getProjectLedgerDetailsSuccess,
    getProjectLedgerFailure,
    setProcessLedgerData,
} from './ProgressLedger/ProgressLedger.slice'
import {
    getProgressLedgerCreditData,
    getProgressLedgerCreditFailure,
    getProgressLedgerCreditSuccess,
    setProgressLedgerCreditData,
} from './ProgressLedgerCredit/ProgressLedgerCredit.slice'
import {
    getProgressSubLedgerData,
    getProgressSubLedgerFailure,
    getProgressSubLedgerSuccess,
} from './ProgressSubLedger/ProgressSubLeger.slice'
import {
    getProgressSubLedgerCreditData,
    getProgressSubLedgerCreditFailure,
    getProgressSubLedgerCreditSuccess,
} from './ProgressSubLedgerCredit/ProgressSubLedgerCredit.slice'
import { postProjectData, postProjectFailure, postProjectSuccess } from './Project/AddProject.slice'
import { postHideData, postHideFailure, postHideSuccess } from './Project/HideProject.slice'
import { getProjectData, getProjectDataFailure, getProjectDataSuccess } from './Project/Project.slice'
import { getProjectListData, getProjectListDataFailure, getProjectListDataSuccess } from './Project/ProjectsList.slice'
import { getStikoExcelData, getStikoExcelFailure, getStikoExcelSuccess } from './StikoExcel/StikoExcel.slice'
import { postStikoData, postStikoFailure, postStikoSuccess } from './StikoPage/AddStiko.slice'
import { getStikoPage, getStikoPageFailure, getStikoPageSuccess } from './StikoPage/StikoPage.slice'
import { getStiko, getStikoFailure, getStikoSuccess } from './StikoTable/StikoTable.slice'
import { getYearData, getYearFailure, getYearSuccess } from './Year/Year.slice'

function* watchStatsData(
    action: ReturnType<typeof getStatsData>
): Generator<CallEffect<statsCardsResponse> | PutEffect<AnyAction>, void, statsCardsResponse> {
    try {
        const params = action.payload
        const response = yield call(DashboardService.getStatsData, params)

        yield put(getStatsDataSuccess(response))
    } catch (error) {
        yield put(getStatsDataFailure(error))
    }
}

function* watchProjectDetailsHeaderData(
    action: ReturnType<typeof getProjectDetailsData>
): Generator<CallEffect<ProjectCardsResponse> | PutEffect<AnyAction>, void, ProjectCardsResponse> {
    try {
        const params = action.payload
        const response = yield call(ProjectDetailsservice.getProjectDetailsData, params)

        yield put(getProjectDetailsSuccess(response))
    } catch (error) {
        yield put(getProjectDetailsFailure(error))
    }
}

function* watchProjectLeaderData(
    action: ReturnType<typeof getProjectLeaderData>
): Generator<CallEffect<GetProjectLeaderType[]> | PutEffect<AnyAction>, void, GetProjectLeaderType[]> {
    try {
        const response = yield call(AddProjectService.getProjectLead)
        yield put(getProjectLeaderDataSuccess(response))
    } catch (error) {
        yield put(getProjectLeaderDataFailure(error))
    }
}

function* watchGetLeaderNames(
    action: ReturnType<typeof getLeaderNames>
): Generator<CallEffect<LeaderNameType[]> | PutEffect<AnyAction>, void, LeaderNameType[]> {
    try {
        const response = yield call(GantCreateOrEditService.getLeaderNames)
        yield put(getLeaderNamesSuccess(response))
    } catch (error) {
        yield put(getLeaderNamesFailure(error))
    }
}

function* watchgetProjectLedgerData(
    action: ReturnType<typeof getProjectLedgerData>
): Generator<CallEffect<ProgressLedgerResponse> | PutEffect<AnyAction>, void, ProgressLedgerResponse> {
    try {
        const params = action.payload
        const response = yield call(ProgressLedgerService.getProgressLedgerData, params)

        yield put(getProjectLedgerDetailsSuccess(response))
    } catch (error) {
        yield put(getProjectLedgerFailure(error))
    }
}

function* watchgetProgressLedgerCreditData(
    action: ReturnType<typeof getProgressLedgerCreditData>
): Generator<CallEffect<ProgressLedgerResponse> | PutEffect<AnyAction>, void, ProgressLedgerResponse> {
    try {
        const params = action.payload
        const response = yield call(ProgressLedgerCreditService.getProgressLedgerCreditData, params)

        yield put(getProgressLedgerCreditSuccess(response))
    } catch (error) {
        yield put(getProgressLedgerCreditFailure(error))
    }
}

function* watchCockpitChartData(
    action: ReturnType<typeof getCockpitChartData>
): Generator<CallEffect<CockpitChartResponse> | PutEffect<AnyAction>, void, CockpitChartResponse> {
    try {
        const params = action.payload
        const response = yield call(CockpitChartService.getCockpitChartData, params)
        yield put(getCockpitChartSuccess(response))
    } catch (error) {
        yield put(getCockpitChartFailure(error))
    }
}

function* watchLineChartData(
    action: ReturnType<typeof getLineChartData>
): Generator<CallEffect<LineChartResponse> | PutEffect<AnyAction>, void, LineChartResponse> {
    try {
        const params = action.payload
        const response = yield call(LineChartService.getLineChartData, params)

        yield put(getLineChartSuccess(response))
    } catch (error) {
        yield put(getLineChartFailure(error))
    }
}

function* watchgetProgressSubLedgerData(
    action: ReturnType<typeof getProgressSubLedgerData>
): Generator<CallEffect<ProgressSubLedgerResponse> | PutEffect<AnyAction>, void, ProgressSubLedgerResponse> {
    try {
        const params = action.payload
        const response = yield call(ProgressSubLedgerService.getProgressSubLedgerData, params)
        response?._Data.forEach((item) => {
            item.LedgerId = action.payload.Ledgercode
        })
        yield put(getProgressSubLedgerSuccess(response))
        yield put(setProcessLedgerData(response))
    } catch (error) {
        yield put(getProgressSubLedgerFailure(error))
    }
}

function* watchgetProgressSubLedgerCreditData(
    action: ReturnType<typeof getProgressSubLedgerCreditData>
): Generator<CallEffect<ProgressSubLedgerResponse> | PutEffect<AnyAction>, void, ProgressSubLedgerResponse> {
    try {
        const params = action.payload
        const response = yield call(ProgressSubLedgerCreditService.getProgressSubLedgerCreditData, params)
        response?._Data.forEach((item) => {
            item.LedgerId = action.payload.Ledgercode
        })
        yield put(getProgressSubLedgerCreditSuccess(response))
        yield put(setProgressLedgerCreditData(response))
    } catch (error) {
        yield put(getProgressSubLedgerCreditFailure(error))
    }
}

function* watchgetGaugeData(
    action: ReturnType<typeof getGaugeData>
): Generator<CallEffect<GaugeResponse> | PutEffect<AnyAction>, void, GaugeResponse> {
    try {
        const params = action.payload
        const response = yield call(GaugeService.getGaugeData, params)

        yield put(getGaugeSuccess(response))
    } catch (error) {
        yield put(getGaugeFailure(error))
    }
}

function* watchgetCockpitProjectsData(
    action: ReturnType<typeof getCockpitProjectsData>
): Generator<CallEffect<CockpitProjectsResponse[]> | PutEffect<AnyAction>, void, CockpitProjectsResponse[]> {
    try {
        const response = yield call(CockpitProjectsService.getCockpitProjectsData)

        yield put(getCockpitProjectsSuccess(response))
    } catch (error) {
        yield put(getCockpitProjectsFailure(error))
    }
}

function* watchCockpitLeaderData(
    action: ReturnType<typeof getCockpitLeaderData>
): Generator<CallEffect<CockpitLeaderResponse[]> | PutEffect<AnyAction>, void, CockpitLeaderResponse[]> {
    try {
        const response = yield call(CockpitLeaderService.getCockpitLeaderData)

        yield put(getCockpitLeaderSuccess(response))
    } catch (error) {
        yield put(getCockpitLeaderFailure(error))
    }
}

function* watchgetYearData(
    action: ReturnType<typeof getYearData>
): Generator<CallEffect<[]> | PutEffect<AnyAction>, void, []> {
    try {
        const response = yield call(YearService.getYearData)
        yield put(getYearSuccess(response))
    } catch (error) {
        yield put(getYearFailure(error))
    }
}

function* watchgetMutatiesData(
    action: ReturnType<typeof getMutatiesData>
): Generator<CallEffect<MutatiesResponse> | PutEffect<AnyAction>, void, MutatiesResponse> {
    try {
        const params = action.payload
        const response = yield call(MutatieService.getMutatiesData, params)

        yield put(getMutatiesSuccess(response))
    } catch (error) {
        yield put(getMutatiesFailure(error))
    }
}

function* watchExcelExportMutatiesData(
    action: ReturnType<typeof getExcelMutaties>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const params = action.payload
        const response = yield call(MutatieService.getExcelExportMutatiesData, params)

        yield put(getExcelMutatiesDataSuccess(response))
    } catch (error) {
        yield put(getExcelMutatiesFailure(error))
    }
}

function* watchProjectData(
    action: ReturnType<typeof getProjectData>
): Generator<CallEffect<ProjectMainDataResponse> | PutEffect<AnyAction>, void, ProjectMainDataResponse> {
    try {
        const SendParams = action.payload
        const response = yield call(ProjectTabService.getProjectTabData, SendParams)
        yield put(getProjectDataSuccess(response))
    } catch (error) {
        yield put(getProjectDataFailure(error))
    }
}

function* watchStikoData(
    action: ReturnType<typeof getStiko>
): Generator<CallEffect<stikoResponse> | PutEffect<AnyAction>, void, stikoResponse> {
    try {
        const SendParams = action.payload
        const response = yield call(StikoService.getStikoData, SendParams)
        yield put(getStikoSuccess(response))
    } catch (error) {
        yield put(getStikoFailure(error))
    }
}

function* watchStikoPageData(
    action: ReturnType<typeof getStikoPage>
): Generator<CallEffect<stikoPageRespose> | PutEffect<AnyAction>, void, stikoPageRespose> {
    try {
        const SendParams = action.payload
        const response = yield call(StikoPageService.getStikoPageData, SendParams)
        yield put(getStikoPageSuccess(response))
    } catch (error) {
        yield put(getStikoPageFailure(error))
    }
}

function* watchNodesData(
    action: ReturnType<typeof getNodeData>
): Generator<CallEffect<NodesRootData[]> | PutEffect<AnyAction>, void, NodesRootData[]> {
    try {
        // const { SearchQuery, ProjectNumber, ProjectLeader, ProjectName, Fase1, Fase2, Fase3, PageNumber, PageSize }= action.payload
        const SendParams = action.payload
        const response = yield call(NodesService.getNodesData, SendParams)

        yield put(getNodesDataSuccess(response))
    } catch (error) {
        yield put(getNodesDataFailure(error))
    }
}

function* watchEditNodesData(
    action: ReturnType<typeof getEditNodeData>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, never> {
    try {
        const SendParams = action.payload
        const response = yield call(NodesService.editNodesData, SendParams)
        yield put(getEditNodesDataSuccess(response))
        const initialGetUserParam = {
            SearchQuery: null,
            PageNumber: SendParams.pageNumberForFasering,
            PageSize: 10,
        }
        yield put(getFaseringData(initialGetUserParam))
        yield put(
            getNodeData({
                projectNumber: SendParams.projectNumber,
                locationId: SendParams.locationId,
                IsParent: SendParams.IsParent,
            })
        )
    } catch (error) {
        yield put(getEditNodesDataFailure(error))
    }
}
function* watchFaseringData(
    action: ReturnType<typeof getFaseringData>
): Generator<CallEffect<FaseringMainDataResponse> | PutEffect<AnyAction>, void, FaseringMainDataResponse> {
    try {
        // const { SearchQuery, ProjectNumber, ProjectLeader, ProjectName, Fase1, Fase2, Fase3, PageNumber, PageSize }= action.payload
        const SendParams = action.payload
        const response = yield call(FaseringTabService.getFaseringTabData, SendParams)
        yield put(getFaseringDataSuccess(response))
    } catch (error) {
        yield put(getFaseringDataFailure(error))
    }
}
function* watchMapData(
    action: ReturnType<typeof getMapData>
): Generator<CallEffect<string> | PutEffect<AnyAction>, void, MapMainDataResponse> {
    try {
        // const { SearchQuery, ProjectNumber, ProjectLeader, ProjectName, Fase1, Fase2, Fase3, PageNumber, PageSize }= action.payload
        const SendParams = action.payload
        const response = yield call(MapTabService.getMapTabData, SendParams)
        yield put(getMapDataSuccess(response))
    } catch (error) {
        yield put(getMapDataFailure(error))
    }
}

function* watchPieChartData(
    action: ReturnType<typeof getPieChartData>
): Generator<CallEffect<PieRootData[]> | PutEffect<AnyAction>, void, PieRootData[]> {
    try {
        const SendParams = action.payload
        const response = yield call(PieChartService.getPieChartData, SendParams)
        yield put(getPieChartDataSuccess(response))
    } catch (error) {
        yield put(getPieChartDataFailure(error))
    }
}

function* watchPostProjectData(
    action: ReturnType<typeof postProjectData>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const response = yield call(AddProjectService.postProjectData, action.payload)

        yield put(postProjectSuccess(response))
    } catch (error) {
        yield put(postProjectFailure(error))
    }
}

function* watchPostHideData(
    action: ReturnType<typeof postHideData>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        // const response = yield call(AddProjectService.postHideData, action.payload)

        const SendParams = action.payload
        const response = yield call(AddProjectService.postHideData, SendParams)

        yield put(postHideSuccess(response))
    } catch (error) {
        yield put(postHideFailure(error))
    }
}

function* watchPostStikoData(
    action: ReturnType<typeof postStikoData>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const response = yield call(AddStikoService.postStikoData, action.payload)

        yield put(postStikoSuccess(response))
    } catch (error) {
        yield put(postStikoFailure(error))
    }
}

function* watchPostTimeLineNode(
    action: ReturnType<typeof postTimeLineNode>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const resp = yield call(GantCreateOrEditService.postTimeLineNode, action.payload)
        yield put(postTimeLineNodeSuccess(resp))
    } catch (error: any) {
        yield put(postTimeLineNodeFailure(error?.response?.data))
    }
}

function* watchEditTimeLineNode(
    action: ReturnType<typeof editTimeLineNode>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const resp = yield call(GantCreateOrEditService.editTimeLineNode, action.payload)
        yield put(editTimeLineNodeSuccess(resp))
    } catch (error: any) {
        yield put(editTimeLineNodeFailure(error?.response?.data))
    }
}

function* watchDeleteTimeLine(
    action: ReturnType<typeof deleteTimeLine>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const resp = yield call(GantCreateOrEditService.deleteTimeLine, action.payload)
        yield put(deleteTimeLineSuccess(resp))
    } catch (error) {
        yield put(deleteTimeLineFailure())
    }
}

function* watchgetLocationData(
    action: ReturnType<typeof getLocationData>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const response = yield call(LocationService.getLocationData)

        yield put(getLocationSuccess(response))
    } catch (error) {
        yield put(getLocationFailure(error))
    }
}

function* watchExcelData(
    action: ReturnType<typeof getExcelData>
): Generator<CallEffect<File> | PutEffect<AnyAction>, void, File> {
    try {
        const params = action.payload
        const response = yield call(ExcelService.getExcelData, params)

        yield put(getExcelSuccess(response))
    } catch (error) {
        yield put(getExcelFailure(error))
    }
}

function* watchStikoExcelData(
    action: ReturnType<typeof getStikoExcelData>
): Generator<CallEffect<File> | PutEffect<AnyAction>, void, File> {
    try {
        const params = action.payload
        const response = yield call(StikoExcelService.getStikoExcelData, params)

        yield put(getStikoExcelSuccess(response))
    } catch (error) {
        yield put(getStikoExcelFailure(error))
    }
}

function* watchProjectListData(
    action: ReturnType<typeof getProjectListData>
): Generator<CallEffect<File> | PutEffect<AnyAction>, void, ProjectsListResponse[]> {
    try {
        const params = action.payload
        const response = yield call(ProjectListService.getProjectListsData, params)

        yield put(getProjectListDataSuccess(response))
    } catch (error) {
        yield put(getProjectListDataFailure(error))
    }
}

function* watchgetGannChartData(
    action: ReturnType<typeof getGannChartData>
): Generator<CallEffect<GannChartDataResponse> | PutEffect<AnyAction>, void, GannChartDataResponse> {
    try {
        const params = action.payload
        const response = yield call(GannChartService?.getGannChartData, params)

        yield put(getGannChartDataSuccess(response))
    } catch (error) {
        yield put(getGannChartDataFailure(error))
    }
}

function* watchUpdateDragorMoveTimedataNode(
    action: ReturnType<typeof updateDragorMoveTimelineNode>
): Generator<CallEffect<UpdateDragorMoveTimelineNodeParams> | PutEffect<AnyAction>, void, any> {
    try {
        const params = action.payload
        const response = yield call(GantCreateOrEditService.updateDragorMoveTimelineNode, params)

        yield put(updateDragorMoveTimelineNodeSuccess(response))
    } catch (error: any) {
        yield put(updtaeDragorMoveTimelineNodeFailure(error?.response?.data))
    }
}

const DashboardSaga = [
    takeLatest(getStatsData, watchStatsData),
    takeLatest(getProjectData, watchProjectData),
    takeLatest(getProjectDetailsData, watchProjectDetailsHeaderData),
    takeLatest(getProjectLedgerData, watchgetProjectLedgerData),
    takeLatest(getProgressLedgerCreditData, watchgetProgressLedgerCreditData),
    takeLatest(getNodeData, watchNodesData),
    takeLatest(getEditNodeData, watchEditNodesData),
    takeLatest(getFaseringData, watchFaseringData),
    takeLatest(getMapData, watchMapData),
    takeLatest(getProgressSubLedgerData, watchgetProgressSubLedgerData),
    takeLatest(getGaugeData, watchgetGaugeData),
    takeLatest(getMutatiesData, watchgetMutatiesData),
    takeLatest(getExcelMutaties, watchExcelExportMutatiesData),
    takeLatest(getCockpitProjectsData, watchgetCockpitProjectsData),
    takeLatest(getYearData, watchgetYearData),
    takeLatest(getCockpitLeaderData, watchCockpitLeaderData),
    takeLatest(getLineChartData, watchLineChartData),
    takeLatest(getCockpitChartData, watchCockpitChartData),
    takeLatest(getPieChartData, watchPieChartData),
    takeLatest(postProjectData, watchPostProjectData),
    takeLatest(getLocationData, watchgetLocationData),
    takeLatest(getProgressSubLedgerCreditData, watchgetProgressSubLedgerCreditData),
    takeLatest(getExcelData, watchExcelData),
    takeLatest(getStiko, watchStikoData),
    takeLatest(getStikoExcelData, watchStikoExcelData),
    takeLatest(getStikoPage, watchStikoPageData),
    takeLatest(postStikoData, watchPostStikoData),
    takeLatest(getProjectLeaderData, watchProjectLeaderData),
    takeLatest(getLeaderNames, watchGetLeaderNames),
    takeLatest(postHideData, watchPostHideData),
    takeLatest(getProjectListData, watchProjectListData),
    takeLatest(getGannChartData, watchgetGannChartData),
    takeLatest(postTimeLineNode, watchPostTimeLineNode),
    takeLatest(editTimeLineNode, watchEditTimeLineNode),
    takeLatest(deleteTimeLine, watchDeleteTimeLine),
    takeLatest(updateDragorMoveTimelineNode, watchUpdateDragorMoveTimedataNode),
]
export default DashboardSaga
