import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProgressSubLederCredittypes, ProgressSubLederCreditParams } from './ProgressSubLedgerCredit.types'
import { ProgressSubLedgerResponse } from '@app-types/ProgressSubLedgerResponse.types'

const initstage: ProgressSubLederCredittypes = {
    progressSubLedgerCreditLoading: false,
    progressSubLedgerCreditData: null,
    progressSubLedgerCreditError: null,
    // "isSucess": true,
    // "message": "Project header fetched successfully!!!"
}

const ProgressSubLedgerCreditSlice = createSlice({
    name: 'ProgressSubLedgerCreditSlice',
    initialState: initstage,
    reducers: {
        getProgressSubLedgerCreditData(state, action: PayloadAction<ProgressSubLederCreditParams>) {
            state.progressSubLedgerCreditLoading = true
            state.progressSubLedgerCreditError = null
        },
        getProgressSubLedgerCreditSuccess(state, action: PayloadAction<ProgressSubLedgerResponse>) {
            state.progressSubLedgerCreditData = action.payload
            state.progressSubLedgerCreditLoading = false
            state.progressSubLedgerCreditError = null
        },
        getProgressSubLedgerCreditFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getProgressSubLedgerCreditData, getProgressSubLedgerCreditSuccess, getProgressSubLedgerCreditFailure } =
ProgressSubLedgerCreditSlice.actions
export default ProgressSubLedgerCreditSlice.reducer
