import { DELETE, GET, POST, PUT } from '@api/ApiService'
import { AddOrEditParams, DeleteParams, userDataParams } from '@users/store/user.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    getUserData: () => `${BASE_URL}/api/User`,
    getUserRoleData: () => `${BASE_URL}/api/user/roles`,
    addUser: () => `${BASE_URL}/api/User`,
    editUser: (userId: number) => `${BASE_URL}/api/User/${userId}`,
    deleteUser: (id: number, WorkspaceID: number) => `${BASE_URL}/api/User/${id}?WorkspaceID=${WorkspaceID}`,
}

const UserService = {
    getUserData: async (requestParam: userDataParams) => {
        return GET(urlFactory.getUserData(), requestParam)
    },
    getUserRoleData: async () => {
        return GET(urlFactory.getUserRoleData())
    },
    addUser: async (postParam: AddOrEditParams) => {
        return POST(urlFactory.addUser(), postParam)
    },
    editUser: async (postParam: AddOrEditParams, userId: number) => {
        return PUT(urlFactory.editUser(userId), postParam)
    },
    deleteUser: async (data: DeleteParams) => {
        return DELETE(urlFactory.deleteUser(data.Id, data.WorkspaceID))
    },
}

export default UserService
