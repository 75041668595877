import { GET } from '@api/ApiService'
import { statsCardsParams } from '@dashboard/store/StatisticsCards/StatsCards.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"
const urlFactory = {
    getStatsData: (sendParams: statsCardsParams) =>
        `${BASE_URL}/api/Header/StatisticCards?ishidden=${sendParams?.ishidden}`,
}

const DashboardService = {
    getStatsData: async (sendParams: statsCardsParams) => {
        return GET(urlFactory.getStatsData(sendParams))
    },
}

export default DashboardService
