import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { DeleteParams, userDataParams, userDataResponse, userState } from './user.types'

const initstage: userState = {
    handleGetUserLoading: false,
    handleGetUserData: {
        count: 0,
        totalPages: 0,
        zBEmployees: [],
    },
    handleGetUserError: {},

    getUserRoleLoading: false,
    getUserRoleData: [],
    getUserRoleError: null,

    addOrEditUserLoadiing: false,
    addOrEditUserData: null,
    addOrEditUserError: null,

    deleteUserLoading: false,
    deleteUser: null,
    deleteUsererror: null,
}

const UserSlice = createSlice({
    name: 'YearSlice',
    initialState: initstage,
    reducers: {
        getUserData(state, action: PayloadAction<userDataParams>) {
            state.handleGetUserLoading = true
            state.handleGetUserError = {}
        },
        getUserSuccessData(state, action: PayloadAction<userDataResponse>) {
            state.handleGetUserData = action.payload
            state.handleGetUserLoading = false
            state.handleGetUserError = {}
        },
        getUserFailureData(state, action: PayloadAction<any>) {
            state.handleGetUserLoading = false
            state.handleGetUserError = action.payload
        },

        handleGetUserRole(state, action: PayloadAction) {
            state.getUserRoleLoading = true
            state.getUserRoleError = {}
        },
        handleUserRoleSucess(state, action: PayloadAction<any>) {
            state.getUserRoleData = action.payload
            state.getUserRoleLoading = false
            state.getUserRoleError = {}
        },
        handleUserRoleFailure(state, action: PayloadAction<any>) {
            state.getUserRoleLoading = false
            state.getUserRoleError = action.payload
        },

        handleAddOrEditUser(state, action: PayloadAction<any>) {
            state.addOrEditUserLoadiing = true
            state.addOrEditUserError = null
        },
        handleAddOrEditUserSuccess(state, action: PayloadAction<any>) {
            state.addOrEditUserLoadiing = false
            state.addOrEditUserData = action.payload
        },
        handleAddOrEditUserFailure(state, action: PayloadAction<any>) {
            state.addOrEditUserError = action.payload
            state.addOrEditUserLoadiing = false
        },

        handleDeleteUser(state, action: PayloadAction<DeleteParams>) {
            state.deleteUserLoading = true
            state.deleteUsererror = null
        },
        handleDeleteUserSuccess(state, action: PayloadAction<any>) {
            state.deleteUserLoading = false
            state.deleteUser = action.payload
        },
        handleDeleteUserFailure(state, action: PayloadAction<any>) {
            state.deleteUsererror = action.payload
            state.deleteUserLoading = false
        },
    },
})
export const {
    getUserData,
    getUserSuccessData,
    getUserFailureData,
    handleGetUserRole,
    handleUserRoleFailure,
    handleUserRoleSucess,
    handleAddOrEditUserFailure,
    handleAddOrEditUserSuccess,
    handleAddOrEditUser,
    handleDeleteUserSuccess,
    handleDeleteUser,
    handleDeleteUserFailure,
} = UserSlice.actions
export default UserSlice.reducer
