import {  POST } from '@api/ApiService'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"


const urlFactory = {
    postStikoData: () => `${BASE_URL}/api/Stiko/AddStiko`
}

const AddStikoService = {
    postStikoData: async (data:any):Promise<Response> => {
        return POST(urlFactory.postStikoData(),data);
    },

}

export default AddStikoService