import { GET } from '@api/ApiService'
import { MutatiesParams } from '@dashboard/store/Mutaties/Mutaties.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
    getMutatiesData: (sendParams: MutatiesParams) =>
        `${BASE_URL}/api/ProgressBars/Mutations?projectNumber=${sendParams.projectNumber}&ledgerCode=${sendParams.ledgerCode}&subLedgerCode=${sendParams.subLedgerCode}&locationId=${sendParams?.locationId}&isParent=${sendParams?.IsParent}&Searchquery=${sendParams?.Searchquery}&Boekdatum=${sendParams?.Boekdatum}&Boekingsnummer=${sendParams?.Boekingsnummer}&Relatienaam=${sendParams?.Relatienaam}&Omschrijving=${sendParams?.Omschrijving}&Bedrag2Formatted=${sendParams?.Bedrag2Formatted}&Debet_Credit=${sendParams?.Debet_Credit}&Dagboek=${sendParams?.Dagboek}&IsAscending=${sendParams?.IsAscending}&KeyProperty=${sendParams?.KeyProperty}&pagesize=${sendParams?.pagesize}&pagenumber=${sendParams?.pagenumber}`,
    getExcelExportMutatiesData: (sendParams: MutatiesParams) =>
        `${BASE_URL}/api/ProgressBars/MutationsExcel?projectNumber=${sendParams.projectNumber}&ledgerCode=${sendParams.ledgerCode}&subLedgerCode=${sendParams.subLedgerCode}&locationId=${sendParams?.locationId}&isParent=${sendParams?.IsParent}&Searchquery=${sendParams?.Searchquery}&Boekdatum=${sendParams?.Boekdatum}&Boekingsnummer=${sendParams?.Boekingsnummer}&Relatienaam=${sendParams?.Relatienaam}&Omschrijving=${sendParams?.Omschrijving}&Bedrag2Formatted=${sendParams?.Bedrag2Formatted}&Debet_Credit=${sendParams?.Debet_Credit}&Dagboek=${sendParams?.Dagboek}&IsAscending=${sendParams?.IsAscending}&KeyProperty=${sendParams?.KeyProperty}&pagesize=${sendParams?.pagesize}&pagenumber=${sendParams?.pagenumber}`,
}

const MutatieService = {
    

    getMutatiesData: async (sendParams: MutatiesParams) => {
        return GET(urlFactory.getMutatiesData(sendParams))
    },

    getExcelExportMutatiesData: async (sendParams: MutatiesParams) => {
        return GET(urlFactory.getExcelExportMutatiesData(sendParams))
    },
}

export default MutatieService
