import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initstage= {
   LocationLoading:false,
   LocationData:[],
   LocationError:null
  
}

const LocationSlice = createSlice({
    name: 'LocationSlice',
    initialState: initstage,
    reducers: {
        getLocationData(state, action:PayloadAction) {
            state.LocationLoading = true
            state.LocationError = null
        },
        getLocationSuccess(state, action: PayloadAction<any>) {
            state.LocationData = action.payload
            state.LocationLoading = false
            state.LocationError = null
        },
        getLocationFailure(state, action: PayloadAction<any>) {}
    },
})
export const { getLocationData,getLocationSuccess,getLocationFailure } = LocationSlice.actions
export default LocationSlice.reducer
