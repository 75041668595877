import { getUserParams } from '@app-types/ProjectTabResponse.types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { ExcelTypes } from './ExcelFile.types'

const initstage: ExcelTypes = {
    ExcelLoading: false,
    ExcelData: null,
    ExcelError: null,
}

const ExcelSlice = createSlice({
    name: 'ExcelSlice',
    initialState: initstage,
    reducers: {
        getExcelData(state, action: PayloadAction<getUserParams>) {
            state.ExcelLoading = true
            state.ExcelError = null
        },
        getExcelSuccess(state, action: PayloadAction<any>) {
            state.ExcelData = action.payload
            state.ExcelLoading = false
            state.ExcelError = null
        },
        getExcelFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getExcelData, getExcelSuccess, getExcelFailure } = ExcelSlice.actions
export default ExcelSlice.reducer
