import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { HideProjectParams } from './Project.types'

interface InitState {
    HideLoading: null | boolean
    HideSucess: null | boolean
    HideError: any
}

const initstage: InitState = {
    HideLoading: null,
    HideSucess: null,
    HideError: null,
}

const HideSlice = createSlice({
    name: 'HideSlice',
    initialState: initstage,
    reducers: {
        postHideData(state, action: PayloadAction<HideProjectParams>) {
            state.HideLoading = true
            state.HideError = null
            state.HideSucess = null
        },
        postHideSuccess(state, action: PayloadAction<any>) {
            state.HideSucess = true
            state.HideLoading = false
            state.HideError = null
        },
        postHideFailure(state, action: PayloadAction<any>) {
            state.HideSucess = action.payload.isSucess
            state.HideLoading = false
            state.HideError = action.payload
        },
    },
})
export const { postHideData, postHideFailure, postHideSuccess } = HideSlice.actions
export default HideSlice.reducer
