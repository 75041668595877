import { GET } from '@api/ApiService'
import { projectLedgerParams } from '@dashboard/store/ProgressLedger/ProgressLedger.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getProgressLedgerData: (sendParams:projectLedgerParams) => `${BASE_URL}/api/ProgressBars/Ledgers?projectNumber=${sendParams.projectNumber}&locationId=${sendParams.locationId}&isParent=${sendParams?.IsParent}&isCredit=${sendParams?.isCredit}`
}

const ProgressLedgerService = {
    getProgressLedgerData: async (sendParams:projectLedgerParams) => {
        return GET(urlFactory.getProgressLedgerData(sendParams));
    },

}

export default ProgressLedgerService