import { GET } from '@api/ApiService'
import { PieChartParams } from '@app-types/PieChartResponse.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = 'https://nsldev.nimble.expert/ZwanenburgAPI'

const urlFactory = {
    
        getPieChartData: (sendParams:PieChartParams) =>
     
        `${BASE_URL}/api/ProgressBars/DonutChart?locationId=${sendParams.LocationId}&projectNumber=${sendParams.projectNumber}&isParent=${sendParams?.IsParent}`,
       
}

const PieChartService = {
    getPieChartData: async (sendParams:PieChartParams) => {
        return GET(urlFactory.getPieChartData(sendParams))
        
    },
   
}

export default PieChartService