import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { GaugeTypes, GaugeParams } from './Gauge.types'
import { GaugeResponse } from '@app-types/GaugeResponse.types'

const initstage: GaugeTypes = {
    GaugeLoading: false,
    GaugeData: null,
    GaugeError: null,
}

const GaugeSlice = createSlice({
    name: 'Gauge',
    initialState: initstage,
    reducers: {
        getGaugeData(state, action: PayloadAction<GaugeParams>) {
            state.GaugeLoading = true
            state.GaugeError = null
        },
        getGaugeSuccess(state, action: PayloadAction<GaugeResponse>) {
            state.GaugeData = action.payload
            state.GaugeLoading = false
            state.GaugeError = null
        },
        getGaugeFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getGaugeData, getGaugeSuccess, getGaugeFailure } = GaugeSlice.actions
export default GaugeSlice.reducer
