import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { StatsCardsType , statsCardsParams} from './StatsCards.types'
import { statsCardsResponse } from '@app-types/StatsResponse.types'

const initstage: StatsCardsType = {
    StatsCardsLoading: false,
    StatsCardsData: null,
    StatsCardsError: null,
    // "isSucess": true,
    // "message": "Project header fetched successfully!!!"
}

const StatsSlice = createSlice({
    name: 'StatsSlice',
    initialState: initstage,
    reducers: {
        getStatsData(state, action: PayloadAction<statsCardsParams>) {
            state.StatsCardsLoading = true
            state.StatsCardsError = null
        },
        getStatsDataSuccess(state, action: PayloadAction<statsCardsResponse>) {
            state.StatsCardsData = action.payload
            state.StatsCardsLoading = false
            state.StatsCardsError = null
        },
        getStatsDataFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getStatsData, getStatsDataSuccess, getStatsDataFailure } = StatsSlice.actions
export default StatsSlice.reducer
