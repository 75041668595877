import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SSOValidator from '@authentication/components/ssoValidator/SSOValidator'
import loadable from '@loadable/component'
import Box from '@mui/material/Box'

import PrivateRoutes from './Private.route'

const LoginPage = React.lazy(async () => await import('@authentication/pages/Login.page'))
const NotFoundPage = loadable(async () => import('@shared/components/notFound/NotFound.component'), {
    resolveComponent: (components) => components.default,
})

const DashboardPage = loadable(async () => import('@dashboard/pages/Dashboard.page'), {
    resolveComponent: (components) => components.default,
})

const ProjectDetails = loadable(async () => import('../modules/dashboard/components/ProjectDetails/ProjectDetails'), {
    resolveComponent: (components) => components.default,
})

const Feedback = loadable(async () => import('@dashboard/pages/Feedback'), {
    resolveComponent: (components) => components.default,
})

const AddProject = loadable(async () => import('@projects/pages/Project'), {
    resolveComponent: (components) => components.default,
})

const StikoPage = loadable(async () => import('@dashboard/pages/StikoPage'), {
    resolveComponent: (components) => components.default,
})

const ProjectPage = loadable(async () => import('@projects/pages/Project'), {
    resolveComponent: (components) => components.default,
})

const UsersPage = loadable(async () => import('@users/pages/Users'), {
    resolveComponent: (components) => components.default,
})

const Loading: React.FC = () => <p>Loading ...</p>

const BASE_PATH = process.env.REACT_APP_BASE_PATH

const MainRoutes: React.FC = () => {
    return (
        <React.Suspense fallback={<Loading />}>
            <BrowserRouter basename={BASE_PATH}>
                <Box sx={{ display: 'flex' }}>
                    <Routes>
                        <Route element={<SSOValidator />} path="/" />
                        <Route element={<PrivateRoutes />}>
                            <Route element={<DashboardPage />} path="/home" />
                            <Route element={<ProjectDetails />} path="/ProjectDetails" />
                            <Route element={<ProjectDetails />} path="/ProjectDetails/:subProjectId" />
                            <Route element={<AddProject />} path="/nieuwproject" />
                            <Route element={<StikoPage />} path="/StikoPage" />
                            <Route element={<Feedback />} path="/feedback" />
                            <Route element={<ProjectPage />} path="/ProjectPage" />
                            {/* <Route element={<UsersPage />} path="/UsersPage" /> */}
                            <Route element={<UsersPage />} path="/userPage" />
                        </Route>

                        <Route element={<LoginPage />} path="/login" />
                        <Route element={<NotFoundPage />} path="*" />
                    </Routes>
                </Box>
            </BrowserRouter>
        </React.Suspense>
    )
}

export default MainRoutes
