import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { StikoPageType, StikoPageParams } from './StikoPage.types'
import { stikoPageRespose } from '@app-types/StikoPageResponse.types'


const initstage: StikoPageType = {
    stikoPageLoading: false,
    stikoPage: null,
    stikoPageError: null,
}

const StikoPageSlice = createSlice({
    name: 'StikoPageSlice',
    initialState: initstage,
    reducers: {
        getStikoPage(state, action: PayloadAction<StikoPageParams>) {
            state.stikoPageLoading = true
            state.stikoPageError = null
        },
        getStikoPageSuccess(state, action: PayloadAction<stikoPageRespose>) {
            state.stikoPage = action.payload
            state.stikoPageLoading = false
            state.stikoPageError = null
        },
        getStikoPageFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getStikoPage, getStikoPageSuccess, getStikoPageFailure } = StikoPageSlice.actions
export default StikoPageSlice.reducer
