import { GET } from '@api/ApiService'
// import { getUserParams } from '@app-types/MapTapResponse.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"
const urlFactory = {
    getMapTabData: (searchParam:string) => {
        // let dynamicURL = `${BASE_URL}/api/Project/LocationOnMap`;
        const dynamicURL = `${BASE_URL}/api/Project/LocationOnMapV0?searchParam=${searchParam}`
  //       const queryParameters = [];

  //       if (sendParams.ProjectNumber!==null) {
  //         queryParameters.push(`ProjectNumber=${sendParams.ProjectNumber}`);
  //       }

  //       if (sendParams.projectStatus!==null) {
  //         const projectStatusNumber = parseInt(sendParams.projectStatus);
  // if (!isNaN(projectStatusNumber)) {
  //   queryParameters.push(`projectStatus=${projectStatusNumber}`);
  // }
  //       }

  //     if (queryParameters.length > 0) {
  //       dynamicURL += `?${queryParameters.join('&')}`;
  //     }

      return dynamicURL;
    }
}

const MapTabService = {
    getMapTabData: async ( sendParams:string) => {
        return GET(urlFactory.getMapTabData(sendParams))
    },


}

export default MapTabService