import { GET, POST, PUT } from '@api/ApiService'
import { HideProjectParams } from '@dashboard/store/Project/Project.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    postProjectData: () => `${BASE_URL}/api/Project/Project`,
    getProjectLead: () => `${BASE_URL}/api/Project/ProjectLeader`,
    postHideData: (sendParams: HideProjectParams) =>
        `${BASE_URL}/api/Project/EyeIcon?locationId=${sendParams?.locationId}&ishidden=${sendParams?.ishidden}`,
}

const AddProjectService = {
    postProjectData: async (data: any): Promise<Response> => {
        return POST(urlFactory.postProjectData(), data)
    },
    getProjectLead: async () => {
        return GET(urlFactory.getProjectLead())
    },
    postHideData: async (sendParams: HideProjectParams) => {
        return PUT(urlFactory.postHideData(sendParams), {})
    },
}

export default AddProjectService
