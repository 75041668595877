import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FaseringDataType } from './Fasering.types'
import { FaseringMainDataResponse, getUserParams } from '@app-types/FaseringTabResponse.types'

const initstage: FaseringDataType = {
    FaseringLoading: false,
    FaseringData: null,
    FaseringError: null,
}

const FaseringSlice = createSlice({
    name: 'FaseringSlice',
    initialState: initstage,
    reducers: {
        getFaseringData(state, action: PayloadAction<getUserParams>) {
            state.FaseringLoading = true
            state.FaseringError = null
        },
        getFaseringDataSuccess(state, action: PayloadAction<FaseringMainDataResponse>) {
            state.FaseringData = action.payload
            state.FaseringLoading = false
            state.FaseringError = null
        },
        getFaseringDataFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getFaseringData, getFaseringDataSuccess, getFaseringDataFailure } = FaseringSlice.actions
export default FaseringSlice.reducer
