import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { StikoParams } from '../StikoTable/StikoTable.types'

import { StikoExcelTypes } from './StikoExcel.types'

const initstage: StikoExcelTypes = {
    StikoExcelLoading: false,
    StikoExcelData: null,
    StikoExcelError: null,
}

const StikoExcelSlice = createSlice({
    name: 'StikoExcelSlice',
    initialState: initstage,
    reducers: {
        getStikoExcelData(state, action: PayloadAction<StikoParams>) {
            state.StikoExcelLoading = true
            state.StikoExcelError = null
        },
        getStikoExcelSuccess(state, action: PayloadAction<any>) {
            state.StikoExcelData = action.payload
            state.StikoExcelLoading = false
            state.StikoExcelError = null
        },
        getStikoExcelFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getStikoExcelData, getStikoExcelSuccess, getStikoExcelFailure } = StikoExcelSlice.actions
export default StikoExcelSlice.reducer
