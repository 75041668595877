import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProgressSubLedertypes, ProgressSubLederParams } from './ProgressSubLedger.types'
import { ProgressSubLedgerResponse } from '@app-types/ProgressSubLedgerResponse.types'

const initstage: ProgressSubLedertypes = {
    progressSubLedgerLoading: false,
    progressSubLedgerData: null,
    progressSubLedgerError: null,
    // "isSucess": true,
    // "message": "Project header fetched successfully!!!"
}

const ProgressSubLedgerSlice = createSlice({
    name: 'ProgressSubLedger',
    initialState: initstage,
    reducers: {
        getProgressSubLedgerData(state, action: PayloadAction<ProgressSubLederParams>) {
            state.progressSubLedgerLoading = true
            state.progressSubLedgerError = null
        },
        getProgressSubLedgerSuccess(state, action: PayloadAction<ProgressSubLedgerResponse>) {
            state.progressSubLedgerData = action.payload
            state.progressSubLedgerLoading = false
            state.progressSubLedgerError = null
        },
        getProgressSubLedgerFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getProgressSubLedgerData, getProgressSubLedgerSuccess, getProgressSubLedgerFailure } =
    ProgressSubLedgerSlice.actions
export default ProgressSubLedgerSlice.reducer
