import { CockpitChartResponse } from '@app-types/CockpitChartResponse'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { CockpitChartParams, CockpitChartTypes } from './CockpitChart.types'

const initstage: CockpitChartTypes = {
    CockpitChartLoading: false,
    CockpitChartData: null,
    CockpitChartError: null,
}

const CockpitChartSlice = createSlice({
    name: 'CockpitChart',
    initialState: initstage,
    reducers: {
        getCockpitChartData(state, action: PayloadAction<CockpitChartParams>) {
            state.CockpitChartLoading = true
            state.CockpitChartError = null
        },
        getCockpitChartSuccess(state, action: PayloadAction<CockpitChartResponse>) {
            state.CockpitChartData = action.payload
            state.CockpitChartLoading = false
            state.CockpitChartError = null
        },
        getCockpitChartFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getCockpitChartData, getCockpitChartSuccess, getCockpitChartFailure } = CockpitChartSlice.actions
export default CockpitChartSlice.reducer
