import { ProjectsListResponse } from '@app-types/ProjectTabResponse.types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { ProjectListParams,ProjectListType } from './Project.types'

const initstage: ProjectListType = {
    ProjectListLoading: false,
    ProjectListData: [],
    ProjectListError: null,
}

const ProjectListSlice = createSlice({
    name: 'ProjectListSlice',
    initialState: initstage,
    reducers: {
        getProjectListData(state, action: PayloadAction<ProjectListParams>) {
            state.ProjectListLoading = true
            state.ProjectListError = null
        },
        getProjectListDataSuccess(state, action: PayloadAction<ProjectsListResponse[]>) {
            state.ProjectListData = action.payload
            state.ProjectListLoading = false
            state.ProjectListError = null
        },
        getProjectListDataFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getProjectListData, getProjectListDataSuccess, getProjectListDataFailure } = ProjectListSlice.actions
export default ProjectListSlice.reducer
