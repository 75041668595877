import { GET } from '@api/ApiService'
import { projectDetailParams } from '@dashboard/store/ProjectDetailsHeader/ProjectDetails.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
  // const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getProjectDetailsData: (sendParams:projectDetailParams) => `${BASE_URL}/api/Header/ProjectHeader?ProjectNumber=${sendParams.ProjectNumber}&LocationId=${sendParams.LocationId}&IsParent=${sendParams.IsParent}`
}

const ProjectDetailsService = {
    getProjectDetailsData: async (sendParams:projectDetailParams) => {
        return GET(urlFactory.getProjectDetailsData(sendParams));
    },

}

export default ProjectDetailsService