import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MapDataType } from './Maps.types'
import { MapMainDataResponse } from '@app-types/MapTapResponse.types'

const initstage: MapDataType = {
    MapLoading: false,
    MapData: null,
    MapError: null,
}

const MapSlice = createSlice({
    name: 'MapSlice',
    initialState: initstage,
    reducers: {
        getMapData(state, action: PayloadAction<string>) {
            state.MapLoading = true
            state.MapError = null
        },
        getMapDataSuccess(state, action: PayloadAction<MapMainDataResponse>) {
            state.MapData = action.payload
            state.MapLoading = false
            state.MapError = null
        },
        getMapDataFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getMapData, getMapDataSuccess, getMapDataFailure } = MapSlice.actions
export default MapSlice.reducer
