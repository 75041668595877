import { GET } from '@api/ApiService'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
    getCockpitLeaderData: () => `${BASE_URL}/api/CockPit/LeaderName`
}

const CockpitLeaderService = {
    getCockpitLeaderData: async () => {
        return GET(urlFactory.getCockpitLeaderData());
    },

}

export default CockpitLeaderService