import { createTheme } from '@mui/material/styles'

export const color = {
    white: '#FFFFFE',
    black: '#000',
    grey: '#50606B',
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#0C1B2A',
        },
        secondary: {
            main: '#FCFCFC',
        },
    },
    typography: {
        // fontFamily: 'Montserrat',
        // fontSize: 12,

        h1: {
            fontFamily: 'Montserrat',
            fontSize: 20,
            fontWeight: 'Demi Bold',
        },
        h2: {
            fontFamily: 'Fellix-Regular',
            fontSize: '26px',
            fontWeight: '600',
        },
        h3: {
            fontFamily: 'Fellix-Regular',
            fontSize: '18px',
            fontWeight: '600',
        },
        h4: {
            fontFamily: 'Fellix-Regular',
            fontSize: '16px',
            fontWeight: '400',
        },
        h5: {
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: 'Bold',
        },
    },
})

export default theme
