import { GET } from '@api/ApiService'
import { LineChartParams } from '@dashboard/store/LineChart/LineChart.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getLineChartData: (sendParams:LineChartParams) => `${BASE_URL}/api/ProgressBars/LineChart?projectNumber=${sendParams.projectNumber}&locationId=${sendParams?.locationId}&isParent=${sendParams?.IsParent}`
}

const LineChartService = {
    getLineChartData: async (sendParams:LineChartParams) => {
        return GET(urlFactory.getLineChartData(sendParams));
    },

}

export default LineChartService