import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LineChartTypes , LineChartParams} from './LineChart.types'
import { LineChartResponse } from '@app-types/LineChartResponse.types'
const initstage: LineChartTypes = {
    LineChartLoading: false,
    LineChartData: null,
    LineChartError: null,


}

const LineChartSlice = createSlice({
    name: 'LineChart',
    initialState: initstage,
    reducers: {
        getLineChartData(state, action: PayloadAction<LineChartParams>) {
            state.LineChartLoading = true
            state.LineChartError = null
        },
        getLineChartSuccess(state, action: PayloadAction<LineChartResponse>) {
            state.LineChartData = action.payload
            state.LineChartLoading = false
            state.LineChartError = null
        },
        getLineChartFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getLineChartData, getLineChartSuccess, getLineChartFailure } = LineChartSlice.actions
export default LineChartSlice.reducer
