import {useEffect,useState} from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import MainLayout from '@layout/MainLayout'
import { getWithExpiry } from '@shared/services/LocalStorage'

const PrivateRoutes: React.FC = () => {
    const [signedIn, setSignedIn] = useState(true)
    const token = getWithExpiry('console_admin_token')
    const location = useLocation();

    useEffect(() => {
        setSignedIn(!!token)
    }, [location, token]);

    return signedIn ? (
        <>
            <MainLayout />
            <Outlet />
        </>
    ) : (
        <Navigate to="/login" replace />
    )
}

export default PrivateRoutes
