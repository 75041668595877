import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PieChartDataType } from './PieChart.types'
import { PieRootData,PieChartParams } from '@app-types/PieChartResponse.types'
const initStage: PieChartDataType = {
    PieChartLoading: false,
    PieChartData: null,
    PieChartError: null,
}

const PieChartSlice = createSlice({
    name: 'PieChartSlice',
    initialState: initStage,
    reducers: {
        getPieChartData(state, action: PayloadAction<PieChartParams>) {
            state.PieChartLoading = true
            state.PieChartError = null
        },
        getPieChartDataSuccess(state, action: PayloadAction<PieRootData[]>) {
            state.PieChartLoading = false
            state.PieChartData = action.payload
            state.PieChartError = null
        },
        getPieChartDataFailure(state, action: PayloadAction<any>) {
            state.PieChartLoading = true
            state.PieChartError = action.payload
        },
    },
})

export const { getPieChartData, getPieChartDataSuccess, getPieChartDataFailure } = PieChartSlice.actions
export default PieChartSlice.reducer
