import { GET } from '@api/ApiService'
import { ProjectListParams } from '@dashboard/store/Project/Project.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
   getProjectListsData: (sendParams:ProjectListParams) => `${BASE_URL}/api/ProgressBars/DropDown?locationId=${sendParams.locationId}`
}

const ProjectListService = {
    getProjectListsData: async (sendParams:ProjectListParams) => {
        return GET(urlFactory.getProjectListsData(sendParams));
    },

}

export default ProjectListService