import { GET } from '@api/ApiService'
import { StikoPageParams } from '@dashboard/store/StikoPage/StikoPage.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getStikoPageData: (sendParams:StikoPageParams) => `${BASE_URL}/api/Stiko/GetStikoData?ProjectNumber=${sendParams?.ProjectNumber}&StikoID=${sendParams?.StikoID}&LocationID=${sendParams?.LocationID}`
}

const StikoPageService = {
    getStikoPageData: async (sendParams:StikoPageParams) => {
        return GET(urlFactory.getStikoPageData(sendParams));
    },

}

export default StikoPageService