import { GET } from '@api/ApiService'
import { GaugeParams } from '@dashboard/store/Gauge/Gauge.types'
 const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"

const urlFactory = {
   getGaugeData: (sendParams:GaugeParams) => `${BASE_URL}/api/ProgressBars/GuageData?projectNumber=${sendParams.projectNumber}&isParent=${sendParams.IsParent}&locationId=${sendParams?.locationId}`
}

const GaugeService = {
    getGaugeData: async (sendParams:GaugeParams) => {
        return GET(urlFactory.getGaugeData(sendParams));
    },

}

export default GaugeService